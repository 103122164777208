export interface Page<T> {
  items: T[];
  total: number;
  limit: number;
  offset: number;
}

export interface JobTypeDetail {
  jobTypeID: number;
  name: string;
  professionID: number;
  professionName: string;
  keywords?: string[];
}

export interface JobTypeOnly {
  jobTypeID: number;
  name: string;
}

export interface ProfessionDetail {
  professionID: number;
  name: string;
  jobTypes: JobTypeOnly[];
}

export interface UrlResult {
  url: string;
}

export interface GlobalCoordinate {
  longitude: number;
  latitude: number;
}

export interface TraderSearchOptions {
  centre: GlobalCoordinate;
  professionID?: number;
  jobTypeID?: number;
  clientID?: string;
}

export interface TraderBrief {
  traderID: number;
  name: string;
  rating: number;
  reviews: number;
  jobTypes: ProfessionDetail[];
}

export interface TraderQualityRating {
  qualityID: number;
  rating: number;
}

export interface TraderDetail {
  traderID: number;
  name: string;
  description: string;
  phones: string[];
  emailAddress: string;
  website: string;
  jobTypes: ProfessionDetail[];
  rating: number;
  reviews: number;
  globalCoordinate: GlobalCoordinate;
  address: AddressDetail;
  isAddressPublic: boolean;
  qualityRatings: TraderQualityRating[];
}

export enum TraderState {
  Active = 0,
  AwaitingVerification = 1 << 0,
  SubscriptionRequiresAttention = 1 << 1,
  Unsubscribed = 1 << 2,
  IdentificationRequired = 1 << 3,
  Suspended = 1 << 4,
}

export interface MyTraderDetail extends TraderDetail {
  state: TraderState;
  subscriptionExpiry: string;
  visible: boolean;
}

export interface TraderAddOptions {
  name: string;
  description: string;
  phoneNumber: string | null | undefined;
  emailAddress: string | null | undefined;
  website: string | null | undefined;
  location?: LocationAddOptions;
  jobTypes: number[];
  isLocationPublic: boolean;
}

export interface TraderModifyOptions {
  name?: string | null;
  description?: string | null;
  phoneNumber?: string | null;
  emailAddress?: string | null;
  website?: string | null;
  location?: LocationAddOptions | null;
  jobTypes?: number[] | null;
  isLocationPublic?: boolean | null;
}

export interface TraderAddResponse extends TraderDetail {
  onboardingUrl: string;
}

export interface TradingZoneDetail {
  tradingZoneID: number;
  area: GlobalCoordinate[];
}

export interface TradingZoneAddOptions {
  area: GlobalCoordinate[];
}

export interface AddressAddOptions {
  internalNumber?: string;
  externalNumber: string;
  street: string;
  municipalitySubdivision?: string;
  municipality?: string;
  countrySecondarySubdivision?: string;
  countrySubdivision?: string;
  postcode: string;

  /**Alpha-3 country code */
  country: string;
}

export interface LocationAddOptions {
  address: AddressAddOptions;
  coordinate: GlobalCoordinate;
}

export interface AddressDetail {
  internalNumber?: string;
  externalNumber: string;
  street: string;
  municipalitySubdivision?: string;
  municipality?: string;
  countrySecondarySubdivision?: string;
  countrySubdivision?: string;
  postcode: string;

  /**Alpha-3 country code */
  country: string;
}

export interface LocationDetail {
  locationID?: number;
  address?: AddressDetail;
  globalCoordinate: GlobalCoordinate;
}

export interface TraderContactInfo {
  phoneNumbers: string[];
  emailAddress: string;
  address?: AddressDetail;
  globalCoordinate?: GlobalCoordinate;
  website?: string;
}

export interface InternalTraderBrief extends TraderBrief
{
  subscriptionExpiry: Date;
  phoneNumber: string;
  email: string;
  globalCoordinate: GlobalCoordinate;
}
