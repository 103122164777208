import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MapProfessionDetailsToJobTypeDetailsPipe } from './map-profession-details-to-job-type-details.pipe';
import { RoundPipe } from './round.pipe';

@NgModule({
  declarations: [RoundPipe, MapProfessionDetailsToJobTypeDetailsPipe],
  imports: [CommonModule],
  exports: [RoundPipe, MapProfessionDetailsToJobTypeDetailsPipe],
})
export class ChCommonModule {}
