import { ValidatorFn, Validators } from '@angular/forms';

const websiteRegex =
  /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;
const phoneNumberRegex =
  /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/;

export const containsLowercaseRegex = /^(?=.*[a-z])/;
export const containsUppercaseRegex = /^(?=.*[A-Z])/;
export const containsNumericRegex = /^(?=.*[0-9])/;
export const atLeast8CharactersRegex = /^(?=.{8,})/;

export class ChValidators {
  static phoneNumber: ValidatorFn = (ctrl) => {
    if (
      ctrl.value == null ||
      (typeof ctrl.value === 'string' && ctrl.value.trim() === '')
    ) {
      return null;
    }

    const phonenumberValid = phoneNumberRegex.test(ctrl.value);
    return !phonenumberValid ? { phonenumber: true } : null;
  };
  static website = Validators.pattern(websiteRegex);
  static rfc = Validators.pattern(/[A-Z]{3,4}[0-9]{6}[0-9A-Z]{3}/);
  static postcode = Validators.pattern(/[0-9]{5}/);
  static password: ValidatorFn = (ctrl) => {
    if (ctrl.value == null) {
      return null;
    }
    const passwordValid =
      containsLowercaseRegex.test(ctrl.value) &&
      containsUppercaseRegex.test(ctrl.value) &&
      containsNumericRegex.test(ctrl.value) &&
      atLeast8CharactersRegex.test(ctrl.value);
    return !passwordValid ? { password: true } : null;
  };
}
