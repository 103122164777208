import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { JobTypeChipsComponent } from './job-type-chips.component';
import { ChipModule } from 'primeng/chip';
import { DialogModule } from 'primeng/dialog';
import { JobTypesListBoxModule } from '../job-types-list-box/job-types-list-box.module';
import { ButtonModule } from 'primeng/button';

@NgModule({
  declarations: [JobTypeChipsComponent],
  imports: [
    CommonModule,
    ChipModule,
    DialogModule,
    JobTypesListBoxModule,
    ButtonModule,
  ],
  exports: [JobTypeChipsComponent],
})
export class JobTypeChipsModule {}
