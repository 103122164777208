import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ClientService } from 'src/app/core/client.service';
import { ApiService } from '../api.service';
import { AuthenticationResponse, UserAddOptions, UserInfo } from './zero.model';

@Injectable({
  providedIn: 'root',
})
export class ZeroService {
  constructor(private _api: ApiService, private _client: ClientService) {}

  authenticate(authToken: string) {
    return this._api.Post<AuthenticationResponse>(
      `zero/authenticate?clientId=${this._client.getClientId()}&authtoken=${authToken}`
    );
  }

  renew(refresher: string) {
    return this._api.Get<AuthenticationResponse>(
      `zero/authenticate/renew/${this._client.getClientId()}/${refresher}`
    );
  }

  signInGoogle(accessToken: string) {
    return this._api.Post<AuthenticationResponse>(
      `zero/social/google?accessToken=${accessToken}&clientId=${this._client.getClientId()}`
    );
  }

  linkGoogle(accessToken: string) {
    return this._api.Put<unknown>(
      `zero/social/google?accessToken=${accessToken}&clientId=${this._client.getClientId()}`
    );
  }

  signInFacebook(accessToken: string) {
    return this._api.Post<AuthenticationResponse>(
      `zero/social/facebook?accessToken=${accessToken}&clientId=${this._client.getClientId()}`
    );
  }

  linkFacebook(accessToken: string) {
    return this._api.Put<unknown>(
      `zero/social/facebook?accessToken=${accessToken}&clientId=${this._client.getClientId()}`
    );
  }

  signUp(authCode: string, options: UserAddOptions) {
    return this._api.Post<AuthenticationResponse>(
      `zero/signup/${authCode}?clientId=${this._client.getClientId()}`,
      options
    );
  }

  sendEmailSignUpVerificatinEmail(email: string) {
    return this._api.Post(`zero/signup/with-email/${window.btoa(email)}`);
  }

  sendEmailAuthCode(email: string) {
    return this._api.Post(`zero/authenticate/email-code/${window.btoa(email)}`);
  }

  sendPhoneAuthCode(phone: string) {
    return this._api.Post(`zero/authenticate/sms-code/MX/${phone}`);
  }

  changePasswordFromPhoneCode(code: string, phone: string, password: string) {
    return this._api.Put(
      `zero/recovery/${code}/phone/${phone}/password/${window.btoa(password)}`
    );
  }

  changePasswordFromEmailCode(code: string, email: string, password: string) {
    return this._api.Put(
      `zero/recovery/${code}/email/${window.btoa(email)}/password/${window.btoa(
        password
      )}`
    );
  }

  getUserInfo(): Observable<HttpResponse<UserInfo>> {
    return this._api.Get<UserInfo>(`zero/i`);
  }

  deleteSocial(social: 'google' | 'facebook') {
    return this._api.Delete<unknown>(`zero/social/${social}`);
  }

  sendChangePhoneCode(phoneNumber: string) {
    return this._api.Post<unknown>(`zero/i/phone/send-code/MX/${phoneNumber}`);
  }

  changePhone(phoneNumber: string, code: string) {
    return this._api.Put<unknown>(`zero/i/phone/${phoneNumber}?code=${code}`);
  }

  sendChangeEmailCode(newEmail: string) {
    return this._api.Post<unknown>(
      `zero/i/email/send-code/${window.btoa(newEmail)}`
    );
  }

  changeEmail(newEmail: string, code: string) {
    return this._api.Put<unknown>(
      `zero/i/email/${window.btoa(newEmail)}?code=${code}`
    );
  }
}
