<ul>
  <li>
    <ng-container
      *ngTemplateOutlet="
        isGood;
        context: { $implicit: _containsLowercase$ | async }
      "
    ></ng-container>
    Contiene al menos una minúscula 
  </li>
  <li>
    <ng-container
      *ngTemplateOutlet="
        isGood;
        context: { $implicit: _containsUppercase$ | async }
      "
    ></ng-container
    >Al menos una mayúscula
  </li>
  <li>
    <ng-container
      *ngTemplateOutlet="
        isGood;
        context: { $implicit: _containsNumeric$ | async }
      "
    ></ng-container
    >Al menos un símbolo numérico 
  </li>
  <li>
    <ng-container
      *ngTemplateOutlet="
        isGood;
        context: { $implicit: _atLeast8Characters$ | async }
      "
    ></ng-container
    >Mínimo 8 caracteres 
  </li>
</ul>

<ng-template #isGood let-ok>
  <i *ngIf="ok === true" class="pi pi-check"></i>
  <i *ngIf="ok === false" class="pi pi-times"></i>
</ng-template>
