import { Injectable } from '@angular/core';
import { DateTime, Interval } from 'luxon';
import { EMPTY, catchError, switchMap, timer } from 'rxjs';
import { AuthService } from './auth.service';

const AUTH_RENEWAL_LIFESPAN_PERCENTAGE = 0.8;

@Injectable({
  providedIn: 'root',
})
export class AuthRenewalService {
  constructor(private _auth: AuthService) {
    this._handleAutoRenewAuth();
  }

  private _handleAutoRenewAuth() {
    this._auth.authInfo$
      .pipe(
        switchMap((authInfo) => {
          if (authInfo == null) {
            return EMPTY;
          }
          const now = DateTime.now();
          const expiresAt = DateTime.fromISO(authInfo.expiry);

          const intervalTillExpired = Interval.fromDateTimes(now, expiresAt);
          const minutesTillExpired =
            intervalTillExpired.invalidReason === 'end before start'
              ? 0
              : intervalTillExpired.toDuration().as('minutes');

          const renewAt = now.plus({
            minutes: minutesTillExpired * AUTH_RENEWAL_LIFESPAN_PERCENTAGE,
          });

          return timer(renewAt.toJSDate());
        }),
        switchMap(() => {
          return this._auth.renewCurrentToken();
        }),
        catchError((err) => this._handleError(err))
      )
      .subscribe();
  }

  private _handleError(err: Error) {
    console.error(err);
    return EMPTY;
  }
}
