import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { DialogService } from 'primeng/dynamicdialog';
import { MessageModule } from 'primeng/message';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { mapErrorToString } from '../forms/error';
import { RatelyService } from 'src/app/api/rately/rately.service';
import { SignInDialogComponent } from 'src/app/sign-in-dialog/sign-in-dialog.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-public-review-trader-button',
  standalone: true,
  imports: [CommonModule, ButtonModule, MessageModule],
  templateUrl: './public-review-trader-button.component.html',
  styleUrls: ['./public-review-trader-button.component.scss'],
  providers: [DialogService],
})
export class PublicReviewTraderButtonComponent implements OnInit {
  _sendingReviewLink = new BehaviorSubject(false);
  _error?: string;

  @Input()
  traderID?: number;

  constructor(
    private _auth: AuthService,
    private _dialog: DialogService,
    private _router: Router
  ) {}

  ngOnInit(): void {}

  async _logInIfNeededAndNavigateToReview() {
    try {
      this._sendingReviewLink.next(true);

      const userIsLoggedIn = await firstValueFrom(this._auth.signedIn$);

      if (!userIsLoggedIn) {
        const signInResult = await this._logInWithDialog();
        if (!signInResult) {
          return;
        }
      }
      await this._navigateToReviewTrader();
    } catch (err) {
      this._error = mapErrorToString(
        err,
        'Error: no se puede crear el enlace de revisión.'
      );
    } finally {
      this._sendingReviewLink.next(false);
    }
  }

  private async _navigateToReviewTrader() {
    if (this.traderID == null) {
      return;
    }
    await this._router.navigate(['r', 'trader', this.traderID]);
  }

  private async _logInWithDialog() {
    const dialogRef = this._dialog.open(SignInDialogComponent, {
      header: 'Inicia sesión para dejar una reseña.',
    });
    const result = await firstValueFrom(dialogRef.onClose);
    return !!result;
  }
}
