import { PolygonMapTranslations } from './polygon-map-translations';

export const polygonMapDefaultTranslations: PolygonMapTranslations = {
  save: 'Guardar',
  ordinal: '#',
  area: 'Área',
  noData: 'Sin datos. Usa el mapa para crear zonas.',
  unsaved: '(no guardado)',
  saved: 'Guardado',
  error: 'Error',
  tooManyPolygons: 'Demasiados zonas. No puede tener más de {0} zona.',
  'tooManyPolygons-Plural':
    'Demasiados zonas. No puede crear más de {0} zonas.',
  validationAreaTooLarge:
    'Son demasiado grandes. No puede crear un zona con un área superior a {0} kilómetro cuadrado.',
  'validationAreaTooLarge-Plural':
    'Son demasiado grandes. No puede crear un zona con un área superior a {0} kilómetros cuadrados.',
  validationPointsExceedLimit:
    'Demasiados puntos. Los zonas no pueden tener más de {0} punto.',
  'validationPointsExceedLimit-Plural':
    'Demasiados puntos. Los zonas no pueden tener más de {0} puntos.',
  validationNoCrossedLines:
    'Líneas cruzadas detectadas. No se pueden crear zonas con líneas cruzadas.',
};
