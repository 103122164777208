<p-toast key="error" position="top-center"></p-toast>
<header class="flex">
  <img
    src="assets/ChecaChamba-logo-horizontal-blanco.svg"
    alt="ChecaChamba"
    routerLink="/"
    class="cursor-pointer"
    height="36px"
  />
  <div class="nav">
    <input type="checkbox" id="nav-check">
    <div class="nav-header">
    </div>
    
  </div>
  <app-menu></app-menu>
  <span class="flex-auto"> </span>
</header>
<main>
  <router-outlet></router-outlet>
</main>
<footer>
  <div class="recaptcha-notice">
    Este sitio web está protegido por reCAPTCHA y las 
    <a href="https://policies.google.com/privacy">Política de Privacidad</a> y 
    <a href="https://policies.google.com/terms">Términos de servicio</a> de Google aplican.
  </div>
</footer>
