
<button
  type="button"
  pButton
  icon="pi pi-bars"
  (click)="menu.toggle($event)"
  class="h-2rem mx-2"
  style="background-color: #001D36;"
></button>
<p-menu #menu [popup]="true" [model]="(userMenuItems$ | async) ?? []"></p-menu>
