import {
  Component,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { MessageService } from 'primeng/api';
import {
  Subject,
  finalize,
  forkJoin,
  map,
  of,
  switchMap,
  takeUntil,
} from 'rxjs';
import {
  TraderBrief,
  TraderContactInfo,
  TraderDetail,
} from 'src/app/api/checachamba/checachamba.model';
import { ChecachambaService } from 'src/app/api/checachamba/checachamba.service';
import { mapErrorToString } from '../forms/error';
import { Router } from '@angular/router';

@Component({
  selector: 'app-trader-brief-card',
  templateUrl: './trader-brief-card.component.html',
  styleUrls: ['./trader-brief-card.component.scss'],
  providers: [MessageService],
})
export class TraderBriefCardComponent implements OnInit, OnDestroy {
  @Input()
  trader?: TraderDetail | TraderBrief;

  @Input()
  compact = false;

  @Input()
  @HostBinding('class.no-box-shadow')
  noBoxShadow = false;

  @Input()
  linkTo: 'profile' | 'managment' = 'profile';

  @Input()
  hideContactDetails = false;

  @Input()
  hideReviewButton = false;

  @Input()
  hideViewProfileButton = false;

  private _destroyed$ = new Subject<void>();
  _loadingContactDetails = false;
  _contactDetails?: Partial<TraderContactInfo> & { reverseLookup?: string };

  constructor(
    private _checachamba: ChecachambaService,
    private _message: MessageService,
    private _router: Router
  ) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this._destroyed$.next();
  }

  _openWhatsApp() {
    if (this._contactDetails?.phoneNumbers) {
      let pn: string = this._contactDetails.phoneNumbers[0];
      pn = pn.replace('+', '').replace('-', '').replace(' ', '');
      window.open(
        `https://api.whatsapp.com/send?phone=${pn}&text=Vi%20su%20perfil%20en%20ChecaChamba%20y%20quiero%20más%20información`
      );
    }
  }

  _expandDetails(e: Event) {
    e.preventDefault();
    e.stopImmediatePropagation();
    this._loadingContactDetails = true;
    const traderID = this.trader?.traderID;
    if (traderID == null) {
      this._loadingContactDetails = false;
      return;
    }
    this._checachamba
      .GetTraderContactInfo(traderID)
      .pipe(
        takeUntil(this._destroyed$),
        finalize(() => (this._loadingContactDetails = false)),
        map((response) => response.body),
        switchMap((contactInfo) => {
          const lat = contactInfo?.globalCoordinate?.latitude;
          const lng = contactInfo?.globalCoordinate?.longitude;
          return forkJoin([
            of(contactInfo),
            lat != null && lng != null && contactInfo?.address == null
              ? this._checachamba
                  .ReverseLookup(lng, lat)
                  .pipe(map((response) => response.body))
              : of(null),
          ]);
        })
      )
      .subscribe({
        next: ([response, reverseLookup]) => {
          this._contactDetails = {
            ...response,
            reverseLookup: reverseLookup?.description,
          };
        },
        error: (err) =>
          this._message.add({
            severity: 'error',
            summary: 'Error',
            key: 'get-contact-details-error',
            detail: mapErrorToString(
              err,
              'No se pudieron obtener detalles de contacto'
            ),
          }),
      });
  }

  _viewProfile() {
    this._router.navigate(['profile', this.trader?.traderID]);
  }
}
