<p-messages
  *ngIf="_errorMessage"
  styleClass="m-4"
  severity="error"
  [value]="[{ severity: 'error', summary: 'Error', detail: _errorMessage }]"
>
</p-messages>
<p-messages
  *ngIf="_emailSent"
  [closable]="false"
  [value]="[
    {
      severity: 'success',
      summary: 'Email enviado',
      detail: 'El enlace para cambiar tu contraseña que se mandó a tu correo.'
    }
  ]"
>
</p-messages>
<div class="password-reset-card max-w-22rem mx-auto">
  <header class="text-center flex flex-column gap-1 pb-4">
    <img
      class="mx-auto max-h-4rem"
      src="assets/ChecaChamba-icono-full-color.svg"
      alt=""
    />
    <h3>Restaura tu contraseña</h3>
    <p>Ingresa tu correo para recibir un código de verificación.</p>
  </header>
  <form
    class="flex flex-column gap-5"
    [formGroup]="_emailResetForm"
    (ngSubmit)="_sendPasswordResetEmail()"
  >
    <span>
      <span class="p-float-label">
        <input
          id="float-input"
          type="text"
          pInputText
          formControlName="email"
          class="w-full"
        />
        <label for="float-input">Email</label>
      </span>
      <small
        *ngIf="
                  _emailResetForm.controls.email.invalid &&
                  _emailResetForm.controls.email.errors?.['required'] &&
                  (_emailResetForm.controls.email.touched || _submitted)
                "
        class="p-error"
        >Email es requerido</small
      >
      <small
        *ngIf="
                  _emailResetForm.controls.email.invalid &&
                  _emailResetForm.controls.email.errors?.['email'] &&
                  (_emailResetForm.controls.email.touched || _submitted)
                "
        class="p-error"
        >Por favor ingresa un correo valido</small
      >
    </span>
    <button
      [disabled]="!_emailResetForm.valid || _sendingEmail || _emailSent"
      [icon]="_emailSent ? 'pi pi-check' : ''"
      [label]="_emailSent ? 'Enviado' : 'Continuar'"
      [loading]="_sendingEmail"
      label="Continuar"
      pButton
      type="submit"
    ></button>
  </form>
  <small class="text-center"
    >¿Ya eres miembro? <a [routerLink]="['/auth/login']">Inicia sesión</a></small
  >
  <small class="text-center"
    >¿Aún no eres miembro? <a [routerLink]="['/auth/registrar']">Registrate</a></small
  >
</div>
