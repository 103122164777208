import { Inject, Injectable } from '@angular/core';
import { AuthenticationResponse } from '../api/zero/zero.model';
import { STORAGE_EVENT, filterByKey, toValue } from '@ng-web-apis/storage';
import { Observable, map } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthStorageService {
  constructor(
    @Inject(STORAGE_EVENT)
    private readonly _storeageEvent$: Observable<StorageEvent>
  ) {}

  set(auth: AuthenticationResponse | null | undefined) {
    localStorage.setItem('auth', window.btoa(JSON.stringify(auth)));
  }

  get() {
    return this._parseStorageString(localStorage.getItem('auth'));
  }

  select() {
    return this._storeageEvent$.pipe(
      filterByKey('auth'),
      toValue(),
      map((stringValue) => this._parseStorageString(stringValue))
    );
  }

  private _parseStorageString(
    stringValue: string | null
  ): AuthenticationResponse | null | undefined {
    try {
      return stringValue == null
        ? stringValue
        : JSON.parse(window.atob(stringValue));
    } catch (error) {
      return null;
    }
  }
}
