import {
  FacebookLoginProvider,
  GoogleLoginProvider,
  SocialLoginModule,
} from '@abacritt/angularx-social-login';
import {
  HTTP_INTERCEPTORS,
  HttpClientJsonpModule,
  HttpClientModule,
} from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';
import { DividerModule } from 'primeng/divider';
import { InputTextModule } from 'primeng/inputtext';
import { MenuModule } from 'primeng/menu';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { PasswordModule } from 'primeng/password';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ToastModule } from 'primeng/toast';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RedirectOnUnauthorizedInterceptor } from './auth/redirect-on-unauthorized.interceptor';
import { TokenInterceptor } from './auth/token-interceptor.service';
import { CompleteSignUpComponent } from './complete-sign-up/complete-sign-up.component';
import { CoreModule } from './core/core.module';
import { PasswordComponent } from './password/password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { FacebookLoginButtonModule } from './shared/facebook-login-button/facebook-login-button.module';
import { PhoneNumberInputComponent } from './shared/forms/phone-number-input/phone-number-input.component';
import { PasswordStrengthFeedbackComponent } from './shared/password-strength-feedback/password-strength-feedback.component';
import { PolygonMapConfigModule } from './shared/polygon-map-config/polygon-map-config.module';
import { TraderBriefCardModule } from './shared/trader-brief-card/trader-brief-card.module';
import { SignInDialogComponent } from './sign-in-dialog/sign-in-dialog.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { ChangeEmailDialogComponent } from './user-profile/change-email-dialog/change-email-dialog.component';
import { ChangePhoneDialogComponent } from './user-profile/change-phone-dialog/change-phone-dialog.component';
import { UserProfileComponent } from './user-profile/user-profile.component';

@NgModule({
  declarations: [
    AppComponent,
    SignUpComponent,
    SignInComponent,
    PasswordComponent,
    ResetPasswordComponent,
    CompleteSignUpComponent,
    UserProfileComponent,
    ChangePhoneDialogComponent,
    ChangeEmailDialogComponent,
    SignInDialogComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ButtonModule,
    MenuModule,
    HttpClientModule,
    HttpClientJsonpModule,
    PolygonMapConfigModule.forRoot({
      GOOGLE_MAPS_API_KEY: environment.GMAP_API_KEY,
    }),
    RecaptchaV3Module,
    SocialLoginModule,
    DividerModule,
    ReactiveFormsModule,
    FacebookLoginButtonModule,
    InputTextModule,
    MessagesModule,
    CoreModule,
    TraderBriefCardModule,
    DialogModule,
    ProgressSpinnerModule,
    PhoneNumberInputComponent,
    ToastModule,
    MessageModule,
    PasswordModule,
    PasswordStrengthFeedbackComponent,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RedirectOnUnauthorizedInterceptor,
      multi: true,
    },
    {
      provide: RECAPTCHA_V3_SITE_KEY,
      useValue: environment.reCaptchaSiteKey,
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(environment.googleClientId, {
              oneTapEnabled: false,
            }),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(environment.facebookClientId, {
              version: 'v16.0',
              scope: 'public_profile,email',
            }),
          },
        ],
        onError: (err: any) => {
          console.error(err);
        },
      },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
