import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, of, shareReplay, tap } from 'rxjs';
import { PolygonMapConfig } from '../polygon-map-config/polygon-map-config';

@Injectable({
  providedIn: 'root',
})
export class PolygonMapLoadScriptService {
  // keep script loading observables to prevent loading twice and to support
  // loading from multiple API keys in the same application.
  private _loadedScript = new Map<string, Observable<boolean>>();

  constructor(
    private _config: PolygonMapConfig,
    private _httpClient: HttpClient
  ) {}

  loadScript(apiKey?: string) {
    // default to the one configured in the module
    apiKey = apiKey ?? this._config.GOOGLE_MAPS_API_KEY;

    if (!apiKey) {
      throw new Error(
        'No GOOGLE_MAPS_API_KEY configured. Use the input property or the config parameter of the withConfig method on PolygonMapModule to configure a default.'
      );
    }

    if (!this._loadedScript.has(apiKey)) {
      this._loadedScript.set(
        apiKey,
        // We use the jsonp method here so you do not need to include a <script> tag in index.html. See https://github.com/angular/components/blob/main/src/google-maps/README.md
        // Require library drawing to make polygons user creatable and editable
        // Require library geometry for computing areas
        this._httpClient
          .jsonp(
            `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=drawing,geometry`,
            'callback'
          )
          .pipe(
            map(() => true),
            catchError((e) => {
              console.error(e);
              return of(false);
            }),
            shareReplay({ bufferSize: 1, refCount: false })
          )
      );
    }

    return this._loadedScript.get(apiKey);
  }
}
