import { Injectable } from '@angular/core';
import { PolygonMapConfig } from './polygon-map-config';
import { PolygonMapTranslations } from '../polygon-map/polygon-map-translations';

@Injectable({
  providedIn: 'root',
})
export class PolygonMapLocalizationService {
  constructor(private config: PolygonMapConfig) {}
  localize(value: keyof PolygonMapTranslations, ...args: any[]): string {
    return this._formatString(this.config.translations?.[value] ?? value, args);
  }

  private _formatString(value: string, ...args: any[]): string {
    return value.replace(/{(\d+)}/g, (match, number) =>
      args[number] != null ? args[number] : match
    );
  }
}
