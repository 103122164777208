<ng-container *ngIf="user$ | async as user">
  <header>
    <h1>Tu perfil de usuario</h1>
    <div class="user-details">
      <div class="user-field">
        <div class="user-field-label">Nombre</div>
        <div class="user-field-value">{{ user.name }}</div>
      </div>
      <div class="user-field">
        <div class="user-field-label">Email</div>
        <div class="user-field-value email">{{ user.info?.emailAddress }}</div>
        <button
          class="user-field-edit-button p-button-rounded p-button-text"
          pButton
          label="Cambiar Email"
          (click)="_changeEmail()"
        ></button>
      </div>
      <div class="user-field">
        <div class="user-field-label">Teléfono</div>
        <div
          *ngIf="user.info?.phoneNumber; else noPhone"
          class="user-field-value"
        >
          {{ user.info?.phoneNumber }}
        </div>
        <ng-template #noPhone
          ><div class="user-field-value">
            No hay número de teléfono registrado
          </div></ng-template
        >
        <button
          class="user-field-edit-button p-button-rounded p-button-text"
          pButton
          label="Cambiar número de teléfono registrado"
          (click)="_changePhone()"
        ></button>
      </div>
      <div class="user-field">
        <div class="user-field-label">Contraseña</div>
        <div class="user-field-value">********</div>
        <button
          *ngIf="!_emailSent"
          class="user-field-edit-button p-button-rounded p-button-text"
          pButton
          label="Cambiar Contraseña"
          [loading]="_sendingEmail"
          (click)="_sendChangePasswordEmail()"
        ></button>
        <p
          *ngIf="_emailSent"
          class="text-center"
          class="user-field-edit-button"
        >
          Se ha enviado un enlace para cambiar tu contraseña. Revisa tu bandeja
          de entrada o spam para más instrucciones.
        </p>
      </div>
    </div>
  </header>
  <section>
    <h2>Tus chambeadores</h2>
    <ng-container *ngIf="user.traders.length === 0; else traderList">
      <strong>No hay chambeadores registrados</strong>
      <button
        pButton
        (click)="_registerTrader()"
        label="Registrar Chambeador(a)"
      ></button>
    </ng-container>
    <ng-template #traderList>
      <ng-container
        *ngFor="
          let trader of user.traders;
          trackBy: trackByTraderID;
          last as isLast
        "
      >
        <app-trader-brief-card
          [compact]="true"
          [hideContactDetails]="true"
          [hideReviewButton]="true"
          [linkTo]="'managment'"
          [trader]="trader"
          [noBoxShadow]="true"
        ></app-trader-brief-card>
        <p-divider *ngIf="!isLast"></p-divider>
      </ng-container>
    </ng-template>
  </section>
  <section>
    <h2>Redes sociales vinculadas</h2>

    <div
      class="social-account-card"
      *ngFor="let social of [{ name: 'google' }, { name: 'facebook' }]"
    >
      <h3>{{ social.name | titlecase }}</h3>
      <ng-container *ngIf="user.social[social.name].isLinked; else linkAccount"
        ><div class="social-account-link-status">
          Tu perfil está viculado a
          {{ social.name | titlecase }}.
        </div>
        <div class="link-unlink-button-wrapper">
          <button
            class="p-button-rounded p-button-text"
            [loading]="_unlinkingSocial === social.name"
            pButton
            label="Unlink {{ social.name | titlecase }}"
            (click)="_unlinkSocial(social.name)"
          ></button>
        </div>
      </ng-container>
      <ng-template #linkAccount>
        <div class="social-account-link-status">
          Tu perfil no está vinculado a
          {{ social.name | titlecase }}.
        </div>
        <div class="link-unlink-button-wrapper" [ngSwitch]="social.name">
          <ng-container *ngSwitchCase="'google'">
            <p-message
              *ngIf="_googleAuthError$ | async as googleAuthError"
              severity="error"
              [text]="googleAuthError"
            ></p-message>
            <asl-google-signin-button
              size="large"
              text="continue_with"
              type="standard"
              width="202"
            ></asl-google-signin-button>
          </ng-container>
          <ng-container *ngSwitchCase="'facebook'">
            <p-message
              *ngIf="_facebookAuthError$ | async as facebookAuthError"
              severity="error"
              [text]="facebookAuthError"
            ></p-message>
            <app-facebook-login-button
              (click)="_linkFacebook()"
            ></app-facebook-login-button>
          </ng-container>
        </div>
      </ng-template>
    </div>
    <ng-template #socialAccountList></ng-template>
  </section>
</ng-container>

<app-change-phone-dialog *ngIf="_editingPhone" [(visible)]="_editingPhone">
</app-change-phone-dialog>

<app-change-email-dialog *ngIf="_editingEmail" [(visible)]="_editingEmail">
</app-change-email-dialog>

<p-progressSpinner
  *ngIf="_loading"
  class="flex justify-content-center m-4"
  styleClass="mx-auto"
></p-progressSpinner>
