import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first, firstValueFrom, map } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { mapErrorToString } from '../shared/forms/error';
import { ChValidators } from '../shared/forms/validators';

const mustMatchPassword = (
  control: AbstractControl
): { doesNotMatch: boolean } | null => {
  return control.get('password')?.value !== control.get('verifyPassword')?.value
    ? {
        doesNotMatch: true,
      }
    : null;
};

@Component({
  selector: 'app-complete-sign-up',
  templateUrl: './complete-sign-up.component.html',
  styleUrls: ['./complete-sign-up.component.scss'],
  host: {
    class: 'container mt-4 block',
  },
})
export class CompleteSignUpComponent implements OnInit {
  _passwordResetForm = this._fb.group(
    {
      code: ['', Validators.required],
      email: ['', Validators.required],
      password: ['', [Validators.required, ChValidators.password]],
      verifyPassword: ['', Validators.required],
      name: ['', Validators.required],
    },
    { validators: [mustMatchPassword] }
  );
  _errorMessage?: string;
  _submitted = false;
  _saving = false;

  constructor(
    private _fb: FormBuilder,
    private _route: ActivatedRoute,
    private _router: Router,
    private _auth: AuthService
  ) {}

  ngOnInit(): void {
    this.handleLoadEmailAndCode();
  }

  handleLoadEmailAndCode() {
    this._route.queryParamMap
      .pipe(
        first(),
        map((queryParams) => ({
          email: queryParams.get('email'),
          code: queryParams.get('code'),
        }))
      )
      .subscribe({
        next: (params) => {
          this._passwordResetForm.reset({
            email: params.email,
            code: params.code,
          });
          if (params.email) {
            this._passwordResetForm.controls.email.disable();
          }
        },
      });
  }

  async _signUp() {
    this._passwordResetForm.updateValueAndValidity();
    if (!this._passwordResetForm.valid) {
      return;
    }
    // use getRawValue because email will be disabled when populated by the query param
    const formValue = this._passwordResetForm.getRawValue();
    const password = formValue.password;
    const verifyPassword = formValue.verifyPassword;
    const code = formValue.code;
    const email = formValue.email;
    const name = formValue.name;
    if (
      password == null ||
      verifyPassword == null ||
      code == null ||
      email == null ||
      name == null
    ) {
      return;
    }
    // if (password != verifyPassword) {
    //   this._passwordsDoNotMatch = true;
    //   return;
    // }
    try {
      this._submitted = true;
      this._saving = true;
      await firstValueFrom(this._auth.signUpEmail(code, email, password, name));
      await this._router.navigate(['/onboard']);
    } catch (error) {
      const errorCode =
        error instanceof HttpErrorResponse &&
        error.error.code &&
        error.error.code.length
          ? error.error.code[0]
          : '';

      const message = `No se pudo completar el registro. ${errorCode}`;

      this._errorMessage = mapErrorToString(error, message);
    } finally {
      this._saving = false;
    }
  }
}
