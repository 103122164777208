import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { ToastModule } from 'primeng/toast';
import { AddressSummaryModule } from '../address-summary/address-summary.module';
import { ChCommonModule } from '../common/common.module';
import { JobTypeChipsModule } from '../job-type-chips/job-type-chips.module';
import { RatingAndReviewSummaryModule } from '../rating-and-review-summary/rating-and-review-summary.module';
import { TraderAvatarModule } from '../trader-avatar/trader-avatar.module';
import { TraderBriefCardComponent } from './trader-brief-card.component';
import { RouterModule } from '@angular/router';
import { TraderJobTypesSummaryComponent } from '../trader-job-types-summary/trader-job-types-summary.component';
import { PublicReviewTraderButtonComponent } from '../public-review-trader-button/public-review-trader-button.component';
import { ExternalLinkComponent } from '../external-link/external-link.component';

@NgModule({
  declarations: [TraderBriefCardComponent],
  imports: [
    CommonModule,
    CardModule,
    ButtonModule,
    AddressSummaryModule,
    TraderAvatarModule,
    RatingAndReviewSummaryModule,
    JobTypeChipsModule,
    TraderJobTypesSummaryComponent,
    ChCommonModule,
    ToastModule,
    RouterModule,
    PublicReviewTraderButtonComponent,
    ExternalLinkComponent,
  ],
  exports: [TraderBriefCardComponent],
})
export class TraderBriefCardModule {}
