import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable, first, map } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(private _auth: AuthService, private _router: Router) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (route.data['access'] === 'authenticated') {
      return this._auth.signedIn$.pipe(
        first(),
        map((signedIn) => {
          if (!signedIn) {
            return this._router.createUrlTree(['']);
          }
          return true;
        })
      );
    }
    return true;
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (childRoute.data['access'] === 'authenticated') {
      return this._auth.signedIn$.pipe(
        map((signedIn) => {
          if (!signedIn) {
            return this._router.createUrlTree(['']);
          }
          return true;
        })
      );
    }
    return true;
  }
}
