import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AddressDetail } from 'src/app/api/checachamba/checachamba.model';

@Component({
  selector: 'app-address-summary',
  templateUrl: './address-summary.component.html',
  styleUrls: ['./address-summary.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AddressSummaryComponent),
      multi: true,
    },
  ],
})
export class AddressSummaryComponent implements OnInit, ControlValueAccessor {
  @Input()
  address?: AddressDetail;

  constructor() {}

  writeValue(obj: any): void {
    this.address = obj;
  }

  registerOnChange(fn: any): void {}

  registerOnTouched(fn: any): void {}

  setDisabledState?(isDisabled: boolean): void {}

  ngOnInit(): void {}
}
