import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { groupBy, map } from 'lodash-es';
import { JobTypeDetail } from 'src/app/api/checachamba/checachamba.model';
import { ChecachambaService } from 'src/app/api/checachamba/checachamba.service';

@Component({
  selector: 'app-job-types-list-box',
  templateUrl: './job-types-list-box.component.html',
  styleUrls: ['./job-types-list-box.component.scss'],
})
export class JobTypesListBoxComponent implements OnInit {
  loading = false;
  groupedJobTypes: {
    label: string;
    items: { label: string; value: JobTypeDetail; group: string }[];
  }[] = [];

  @Input()
  public set value(v: JobTypeDetail[]) {
    this.fc.setValue(v, { emitEvent: false });
  }

  public get value(): JobTypeDetail[] {
    return this.fc.value;
  }

  fc = new FormControl();

  constructor(private _checachamba: ChecachambaService) {}

  ngOnInit(): void {
    this._checachamba.GetJobTypes(500, 0).subscribe((res) => {
      const jobTypes = res.body?.items || [];
      this.groupedJobTypes = map(
        groupBy(jobTypes, 'professionID'),
        (jobTypes) => ({
          label: jobTypes[0].professionName,
          items: jobTypes.map((j) => ({
            label: j.name,
            value: j,
            group: jobTypes[0].professionName,
          })),
        })
      );
      this.loading = false;
    });
  }
}
