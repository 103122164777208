<p-chip
  *ngIf="allowAdd"
  icon="pi pi-plus"
  label="Añadir nuevo"
  (click)="_addNewJobType()"
  styleClass="add-new-chip"
  class="my-2"
></p-chip>
<ng-container *ngFor="let profession of _groupedJobTypes$ | async">
  <h4 class="px-0">{{ profession.professsionName }}</h4>
  <div class="flex gap-1 flex-wrap">
    <p-chip
      *ngFor="let jobType of profession.jobTypes"
      [label]="jobType.name"
      [removable]="!disabled"
      (onRemove)="_removeJobType(jobType.jobTypeID)"
      styleClass="custom-chip"
    ></p-chip>
  </div>
</ng-container>
<p-dialog
  *ngIf="allowAdd"
  [(visible)]="selectBoxVisible"
  [modal]="true"
  [draggable]="false"
  header="Seleccionar tipos de empleos"
>
  <app-job-types-list-box #selectBox [value]="value"></app-job-types-list-box>
  <ng-template pTemplate="footer">
    <p-button
      icon="pi pi-check"
      (click)="_changeValue(selectBox.value); selectBoxVisible = false"
      label="Ok"
      styleClass="p-button-text"
    ></p-button>
  </ng-template>
</p-dialog>
