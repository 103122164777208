<div class="flex gap-1 flex-wrap" *ngIf="_jobTypes$ | async as jobTypes">
  <ng-container *ngFor="let jobType of jobTypes">
    <p-chip
      *ngIf="jobType.visible"
      [label]="jobType.name"
      styleClass="custom-chip"
    ></p-chip>
  </ng-container>
  <div
    *ngIf="jobTypes.length > _maxDisplayedJobTypes"
    class="additional-job-types-indicator"
  >
    +{{ jobTypes.length - _maxDisplayedJobTypes }}
  </div>
</div>
