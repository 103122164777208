import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ListboxModule } from 'primeng/listbox';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { JobTypesListBoxComponent } from './job-types-list-box.component';

@NgModule({
  declarations: [JobTypesListBoxComponent],
  imports: [
    CommonModule,
    ListboxModule,
    ReactiveFormsModule,
    ProgressSpinnerModule,
  ],
  exports: [JobTypesListBoxComponent],
})
export class JobTypesListBoxModule {}
