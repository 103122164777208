import { ApplicationRef, Injectable } from '@angular/core';
import { first, shareReplay, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppStabilizedService {
  readonly hasStabilized$ = this.appRef.isStable.pipe(
    first((stable) => stable),
    shareReplay(1)
  );

  constructor(private appRef: ApplicationRef) {}
}
