<p-dialog
  [modal]="true"
  [draggable]="false"
  header="Cambiar Email"
  [style]="{ width: '5in' }"
  [visible]="visible"
  (visibleChange)="visibleChange.emit($event)"
>
  <form *ngIf="!_verificationSent" [formGroup]="_sendChangeEmailCodeForm">
    <div class="">
      <label for="currentemail" class="block">Email Actual</label>
      <input
        class="w-full"
        id="currentemail"
        formControlName="currentEmail"
        pInputText
      />
    </div>
    <div class="mb-0">
      <label for="newemail" class="block">Nuevo Email</label>
      <input
        class="w-full"
        id="newemail"
        type="email"
        formControlName="newEmail"
        pInputText
      />
      <small
        *ngIf="
          _sendChangeEmailCodeForm.controls.newEmail.invalid &&
          _sendChangeEmailCodeForm.controls.newEmail.errors?.['required'] &&
          (_sendChangeEmailCodeForm.controls.newEmail.dirty ||
            _sendChangeEmailCodeFormSubmitted)
        "
        class="p-error"
        >Email es requerido.</small
      >
      <small
        *ngIf="
          _sendChangeEmailCodeForm.controls.newEmail.invalid &&
          _sendChangeEmailCodeForm.controls.newEmail.errors?.['email'] &&
          (_sendChangeEmailCodeForm.controls.newEmail.dirty ||
            _sendChangeEmailCodeFormSubmitted)
        "
        class="p-error"
        >Please enter valid email.</small
      >
    </div>
    <p-message
      *ngIf="_sendChangeEmailCodeErrorMessage"
      styleClass="w-full"
      severity="error"
      [text]="_sendChangeEmailCodeErrorMessage"
    ></p-message>
    <button
      pButton
      label="Verificar Email"
      type="submit"
      [loading]="_savingSendChangeEmailCode"
      (click)="_sendChangeEmailCode()"
    ></button>
  </form>

  <!--
  <form [formGroup]="_changeEmailForm">
    <p>
      Please enter the code sent to {{ _changeEmailForm.value.newEmail }} to
      verify your email address.
    </p>
    <div class="field mb-0">
      <label for="phone" class="block">Code</label>
      <input class="w-full" pInputText formControlName="code" type="text" />
      <small
        *ngIf="
          _changeEmailForm.controls.code.invalid &&
          _changeEmailForm.controls.code.errors?.['required'] &&
          (_changeEmailForm.controls.code.dirty ||
            _changeEmailFormSubmitted)
        "
        class="p-error"
        >Code is required.</small
      >
    </div>
    <p-message
      *ngIf="_changeEmailFormErrorMessage"
      severity="error"
      styleClass="w-full mt-2"
      [text]="_changeEmailFormErrorMessage"
    ></p-message>
  </form> -->

  <ng-container *ngIf="_verificationSent">
    <p-message
      severity="success"
      text="Por favor revisa el enlace que se mandó a tu correo para cambiar la contraseña"
    ></p-message>
  </ng-container>
</p-dialog>
