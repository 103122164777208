import { Pipe, PipeTransform } from '@angular/core';
import {
  JobTypeDetail,
  ProfessionDetail,
} from 'src/app/api/checachamba/checachamba.model';

@Pipe({
  name: 'mapProfessionDetailsToJobTypeDetails',
})
export class MapProfessionDetailsToJobTypeDetailsPipe implements PipeTransform {
  transform(
    professionDetails: ProfessionDetail[] | null | undefined
  ): JobTypeDetail[] {
    return (
      professionDetails?.flatMap((t2) =>
        t2.jobTypes.map((j) => ({
          professionID: t2.professionID,
          professionName: t2.name,
          ...j,
        }))
      ) ?? []
    );
  }
}
