import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { groupBy, mapValues, map as ldMap } from 'lodash-es';
import { map, ReplaySubject, Subject } from 'rxjs';
import { JobTypeDetail } from 'src/app/api/checachamba/checachamba.model';

@Component({
  selector: 'app-job-type-chips',
  templateUrl: './job-type-chips.component.html',
  styleUrls: ['./job-type-chips.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => JobTypeChipsComponent),
      multi: true,
    },
  ],
})
export class JobTypeChipsComponent implements OnInit, ControlValueAccessor {
  private _onChange: any;
  private _onTouched: any;

  @Input()
  disabled: boolean = false;

  private _value: JobTypeDetail[] = [];
  selectBoxVisible: boolean = false;

  @Input()
  public set value(v: JobTypeDetail[]) {
    this._value = v;
    this._valueSubject.next(v);
  }

  public get value(): JobTypeDetail[] {
    return this._value;
  }

  @Output()
  valueChange = new EventEmitter<JobTypeDetail[]>();

  private _valueSubject = new ReplaySubject<JobTypeDetail[]>(1);
  private _value$ = this._valueSubject.asObservable();
  _groupedJobTypes$ = this._value$.pipe(
    map((jobTypes) =>
      ldMap(groupBy(jobTypes, 'professionID'), (j) => ({
        professsionName: j[0].professionName,
        jobTypes: j,
      }))
    )
  );

  @Input()
  allowAdd = false;

  constructor() {}

  writeValue(obj: JobTypeDetail[]): void {
    this.value = obj;
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnInit(): void {}

  _removeJobType(v: number) {
    this._changeValue(this.value.filter((j) => j.jobTypeID !== v));
  }

  _changeValue(v: JobTypeDetail[]) {
    this.value = v;
    this.valueChange.emit(v);
    this._onChange?.(v);
    this._onTouched?.(v);
  }

  _addNewJobType() {
    this.selectBoxVisible = true;
  }

  jobTypesChange(jt: JobTypeDetail[]) {
    this._changeValue(jt);
  }
}
