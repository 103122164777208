import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { CompleteSignUpComponent } from './complete-sign-up/complete-sign-up.component';
import { OnboardGuard } from './trader-onboard/onboard.guard';
import { PasswordComponent } from './password/password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { SignInComponent } from './sign-in/sign-in.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { UserProfileComponent } from './user-profile/user-profile.component';

const routes: Routes = [
  {
    path: 'auth/nuevo',
    component: CompleteSignUpComponent,
  },
  {
    path: 'auth/registrar',
    component: SignUpComponent,
  },
  {
    path: 'auth/login',
    component: SignInComponent,
  },
  {
    path: 'password',
    component: PasswordComponent,
  },
  {
    path: 'resetpassword',
    component: ResetPasswordComponent,
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'buscar',
    loadChildren: () =>
      import('./search-traders/directory.module').then(
        (m) => m.DirectoryModule
      ),
  },
  {
    path: 'onboard',
    loadChildren: () =>
      import('./trader-onboard/onboard.module').then((m) => m.OnboardModule),
    data: { access: 'authenticated' },
    canActivateChild: [AuthGuard, OnboardGuard],
  },
  {
    path: '',
    loadChildren: () =>
      import('./frontpage/frontpage.module').then((m) => m.FrontpageModule),
  },
  {
    path: 'mi-chamba',
    loadChildren: () =>
      import('./trader-dashboard/trader-management.module').then(
        (m) => m.TraderManagementModule
      ),
    data: { access: 'authenticated' },
    canActivateChild: [AuthGuard],
  },
  {
    path: 'checkout',
    loadChildren: () =>
      import('./checkout-callback/checkout-callback.module').then(
        (m) => m.CheckoutCallbackModule
      ),
  },
  {
    path: 'profile',
    loadChildren: () =>
      import('./trader-profile/profile.module').then((m) => m.ProfileModule),
  },
  {
    path: 'r',
    loadChildren: () =>
      import('./review-trader/review.module').then((m) => m.ReviewModule),
  },
  {
    path: 'mi-perfil',
    component: UserProfileComponent,
    data: { access: 'authenticated' },
    canActivate: [AuthGuard],
  },
  {
    path: 'changeemail',
    loadChildren: () =>
      import('./complete-change-email/complete-change-email.module').then(
        (m) => m.CompleteChangeEmailModule
      ),
  },
  {
    path: 'changephone',
    loadChildren: () =>
      import('./complete-change-phone/complete-change-phone.module').then(
        (m) => m.CompleteChangePhoneModule
      ),
  },
  {
    path: 'ext',
    loadChildren: () =>
      import('./external-link/external-link.module').then(
        (m) => m.ExternalLinkModule
      ),
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./admin/admin.module').then(
        (m) => m.AdminModule
      )
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
