import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { first, firstValueFrom, map } from 'rxjs';
import { ZeroService } from '../api/zero/zero.service';
import { mapErrorToString } from '../shared/forms/error';
import { AuthService } from '../auth/auth.service';
import { ChValidators } from '../shared/forms/validators';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
  host: {
    class: 'container mt-4 block',
  },
})
export class ResetPasswordComponent implements OnInit {
  _passwordResetForm = this._fb.group({
    code: ['', Validators.required],
    email: ['', Validators.required],
    password: ['', [Validators.required, ChValidators.password]],
    verifyPassword: ['', Validators.required],
  });
  _errorMessage?: string;
  _submitted = false;
  _saving = false;
  _passwordsDoNotMatch = false;

  _passwordChanged = false;
  _signedIn$ = this._auth.signedIn$;

  constructor(
    private _fb: FormBuilder,
    private _zero: ZeroService,
    private _route: ActivatedRoute,
    private _auth: AuthService
  ) {}

  ngOnInit(): void {
    this._handleLoadEmailAndCode();
  }

  private _handleLoadEmailAndCode() {
    this._route.queryParamMap
      .pipe(
        first(),
        map((queryParams) => ({
          email: queryParams.get('email'),
          code: queryParams.get('code'),
        }))
      )
      .subscribe({
        next: (params) => {
          this._passwordResetForm.reset({
            email: params.email,
            code: params.code,
          });
          if (params.email) {
            this._passwordResetForm.controls.email.disable();
          }
        },
      });
  }

  async _resetPassword() {
    if (!this._passwordResetForm.valid) {
      return;
    }
    const password = this._passwordResetForm.value.password;
    const verifyPassword = this._passwordResetForm.value.verifyPassword;
    const code = this._passwordResetForm.value.code;
    const email = this._passwordResetForm.getRawValue().email;
    if (
      password == null ||
      verifyPassword == null ||
      code == null ||
      email == null
    ) {
      return;
    }
    if (password != verifyPassword) {
      this._passwordsDoNotMatch = true;
      return;
    }
    try {
      this._submitted = true;
      this._saving = true;
      await firstValueFrom(
        this._zero.changePasswordFromEmailCode(code, email, password)
      );
      this._passwordChanged = true;
    } catch (error) {
      this._errorMessage = mapErrorToString(error, 'Error al intentar cambiar la contraseña.');
    } finally {
      this._saving = false;
    }
  }
}
