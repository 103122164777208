import {
  FacebookLoginProvider,
  GoogleLoginProvider,
  SocialAuthService,
} from '@abacritt/angularx-social-login';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { first as _first } from 'lodash-es';
import {
  EMPTY,
  Observable,
  ReplaySubject,
  Subject,
  catchError,
  filter,
  first,
  forkJoin,
  from,
  map,
  of,
  pairwise,
  shareReplay,
  switchMap,
  tap,
} from 'rxjs';
import { TraderDetail } from '../api/checachamba/checachamba.model';
import { ChecachambaService } from '../api/checachamba/checachamba.service';
import { AuthenticationResponse, UserInfo } from '../api/zero/zero.model';
import { ZeroService } from '../api/zero/zero.service';
import { mapErrorToString } from '../shared/forms/error';
import { AuthStorageService } from './auth-storage.service';

export type SocialAccountStatus = {
  isLinked: boolean;
};

export type CurrentUser = {
  name: string;
  info?: UserInfo;
  social: Record<string, SocialAccountStatus>;
  traders: TraderDetail[];
};

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private _authInfoSubject = new ReplaySubject<
    AuthenticationResponse | null | undefined
  >(1);
  readonly authInfo$ = this._authInfoSubject.asObservable();

  readonly user$: Observable<CurrentUser | undefined> = this.authInfo$.pipe(
    switchMap((authInfo) =>
      forkJoin([of(authInfo), this._fetchUserInfo(), this._fetchTraders()])
    ),
    map(([auth, user, traders]) =>
      auth != null && user != null && traders != null
        ? {
            name: auth.name,
            info: user,
            social: {
              google: {
                isLinked:
                  user?.linkedAccounts?.some((v) => v === 'google') ?? false,
              },
              facebook: {
                isLinked:
                  user?.linkedAccounts?.some((v) => v === 'facebook') ?? false,
              },
            },
            traders,
          }
        : undefined
    ),
    catchError((err) => {
      console.error('user$', err);
      throw err;
    }),
    shareReplay(1)
  );

  readonly signedIn$ = this.authInfo$.pipe(
    map(
      (authInfo) => authInfo != null && new Date(authInfo.expiry) > new Date()
    ),
    catchError(() => of(false))
  );

  signInRedirectMode: 'no redirect' | 'default' = 'default';
  socialSignInMode: 'linking' | 'default' = 'default';

  private static readonly _socialErrorMessageGoogle = `No se logró acceder con Google`;
  private static readonly _socialErrorMessageFacebook = `No se logró acceder con Facebook`;
  private _facebookAuthErrorSubject = new Subject<string>();
  readonly facebookAuthError$ = this._facebookAuthErrorSubject.asObservable();
  private _googleAuthErrorSubject = new Subject<string>();
  readonly googleAuthError$ = this._googleAuthErrorSubject.asObservable();
  private _afterRedirectSubject = new Subject<void>();
  readonly afterRedirect$ = this._afterRedirectSubject.asObservable();

  constructor(
    private _zero: ZeroService,
    private _checachamba: ChecachambaService,
    private _router: Router,
    private _social: SocialAuthService,
    private _authStorage: AuthStorageService
  ) {
    this._handleRestoreAuthInfoFromStorage();
    this._handlePostLoginRedirect();
    this._handleGoogleAuthComplete();
  }

  signInEmail(email: string, password: string) {
    return this._zero
      .authenticate(
        window.btoa(`${window.btoa(email)}:${window.btoa(password)}`)
      )
      .pipe(
        map((response) => response.body),
        tap((authInfo) => this._saveAuthInfo(authInfo))
      );
  }

  signInFacebook() {
    return from(this._social.signIn(FacebookLoginProvider.PROVIDER_ID)).pipe(
      switchMap((facebookUser) =>
        this._zero.signInFacebook(facebookUser.authToken)
      ),
      map((response) => response.body),
      tap((authInfo) => this._saveAuthInfo(authInfo)),
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status === 409) {
          this._facebookAuthErrorSubject.next(
            mapErrorToString(error, 'Está cuenta de Facebook ya está en uso.')
          );
        } else {
          this._facebookAuthErrorSubject.next(
            mapErrorToString(error, AuthService._socialErrorMessageFacebook)
          );
        }
        throw error;
      })
    );
  }

  linkFacebook() {
    return from(this._social.signIn(FacebookLoginProvider.PROVIDER_ID)).pipe(
      switchMap((facebookUser) =>
        this._zero.linkFacebook(facebookUser.authToken)
      ),
      catchError((error) => {
        if (error instanceof HttpErrorResponse && error.status === 409) {
          this._facebookAuthErrorSubject.next(
            mapErrorToString(error, 'Está cuenta de Facebook ya está en uso.')
          );
        } else {
          this._facebookAuthErrorSubject.next(
            mapErrorToString(error, AuthService._socialErrorMessageFacebook)
          );
        }
        throw error;
      }),
      switchMap(() => this.renewCurrentToken())
    );
  }

  signUpEmail(
    code: string,
    emailAddress: string,
    password: string,
    name: string,
    phoneNumber?: string
  ) {
    return this._zero
      .signUp(code, {
        emailAddress,
        passwordB64: window.btoa(password),
        name,
        phoneNumber: phoneNumber ?? null,
      })
      .pipe(
        map((response) => response.body),
        tap((authInfo) => this._saveAuthInfo(authInfo))
      );
  }

  signUpFacebook() {
    return this.signInFacebook();
  }

  signOut() {
    this._authStorage.set(null);
    this._authInfoSubject.next(undefined);
    this._social.signOut();
  }

  renewCurrentToken() {
    return this.authInfo$.pipe(
      first(),
      switchMap((authInfo) => this._renewToken(authInfo))
    );
  }

  changeEmail(newEmail: string, code: string) {
    return this._zero
      .changeEmail(newEmail, code)
      .pipe(switchMap(() => this.renewCurrentToken()));
  }

  changePhone(newPhone: string, code: string) {
    return this._zero
      .changePhone(newPhone, code)
      .pipe(switchMap(() => this.renewCurrentToken()));
  }

  unlinkGoogle() {
    return this._zero
      .deleteSocial('google')
      .pipe(switchMap(() => this.renewCurrentToken()));
  }

  unlinkFacebook() {
    return this._zero
      .deleteSocial('facebook')
      .pipe(switchMap(() => this.renewCurrentToken()));
  }

  private _renewToken(authInfo: AuthenticationResponse | null | undefined) {
    const refresher = authInfo?.refresher;
    if (refresher) {
      return this._zero.renew(refresher).pipe(
        map((response) => response.body),
        catchError((err) => {
          if (err instanceof HttpErrorResponse && err.status === 401) {
            this.signOut();
            this._router.navigate(['/']);
          }
          return of(null);
        }),
        tap((authInfo) => {
          this._saveAuthInfo(authInfo);
        })
      );
    } else {
      throw new Error(
        'No se pudo renovar el token, la sesión no está iniciada.'
      );
    }
  }

  private _saveAuthInfo(info: AuthenticationResponse | null | undefined) {
    this._authStorage.set(info);
    this._authInfoSubject.next(info);
  }

  private _fetchUserInfo(): Observable<UserInfo | undefined> {
    return this.signedIn$.pipe(
      first(),
      switchMap((signedIn) => {
        if (signedIn) {
          return this._zero
            .getUserInfo()
            .pipe(map((respones) => respones.body ?? undefined));
        } else {
          return of(undefined);
        }
      }),
      catchError(() => of(undefined))
    );
  }

  private _fetchTraders(): Observable<TraderDetail[] | undefined> {
    return this.signedIn$.pipe(
      first(),
      switchMap((signedIn) => {
        if (signedIn) {
          return this._checachamba
            .GetMyTraders()
            .pipe(map((respones) => respones.body ?? []));
        } else {
          return of([]);
        }
      }),
      catchError(() => of(undefined))
    );
  }

  private _handlePostLoginRedirect() {
    this.user$.pipe(pairwise()).subscribe({
      next: ([previousUser, currentUser]) => {
        if (
          previousUser == null &&
          currentUser != null &&
          this.signInRedirectMode === 'default'
        ) {
          const traders = currentUser.traders;
          if (traders.length === 0) {
            this._router.navigate(['/onboard']);
          } else if (traders.length === 1) {
            this._router.navigate(['/mi-chamba', _first(traders)?.traderID]);
          } else {
            this._router.navigate(['/mi-perfil']);
          }
        }
        this._afterRedirectSubject.next();
      },
    });
  }

  private async _handleRestoreAuthInfoFromStorage() {
    const parsedAuth = this._authStorage.get();
    if (parsedAuth) {
      if (new Date(parsedAuth.expiry) > new Date()) {
        setTimeout(() => {
          this._authInfoSubject.next(parsedAuth);
        }, 0);
      } else {
        setTimeout(() => {
          this._renewToken(parsedAuth).subscribe();
        }, 0);
      }
    } else {
      setTimeout(() => {
        this._authInfoSubject.next(undefined);
      }, 0);
    }
    this._authStorage.select().subscribe((auth) => {
      this._authInfoSubject.next(auth);
    });
  }

  private _handleGoogleAuthComplete() {
    this._social.authState
      .pipe(
        filter((user) => user?.provider === GoogleLoginProvider.PROVIDER_ID),
        switchMap((googleUser) =>
          this.socialSignInMode === 'linking'
            ? this._zero
                .linkGoogle(googleUser.idToken)
                .pipe(switchMap(() => this.renewCurrentToken()))
            : this._zero
                .signInGoogle(googleUser.idToken)
                .pipe(tap((v) => this._saveAuthInfo(v.body)))
        ),
        catchError((error) => {
          if (error instanceof HttpErrorResponse && error.status === 409) {
            this._googleAuthErrorSubject.next(
              mapErrorToString(error, 'Está cuenta de Google ya está en uso.')
            );
          } else {
            this._googleAuthErrorSubject.next(
              mapErrorToString(error, AuthService._socialErrorMessageGoogle)
            );
          }
          return EMPTY;
        })
      )
      .subscribe({});
  }
}
