import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RatingModule } from 'primeng/rating';
import { ChCommonModule } from '../common/common.module';
import { RatingAndReviewSummaryComponent } from './rating-and-review-summary.component';

@NgModule({
  declarations: [RatingAndReviewSummaryComponent],
  imports: [CommonModule, RatingModule, FormsModule, ChCommonModule],
  exports: [RatingAndReviewSummaryComponent],
})
export class RatingAndReviewSummaryModule {}
