import { HttpClientJsonpModule, HttpClientModule } from '@angular/common/http';
import {
  ModuleWithProviders,
  NgModule,
  Optional,
  SkipSelf,
} from '@angular/core';
import { polygonMapDefaultTranslations } from '../polygon-map/polygon-map-default-translations';
import { PolygonMapConfig } from './polygon-map-config';
import { PolygonMapLoadScriptService } from './polygon-map-load-script.service';
import { PolygonMapLocalizationService } from './polygon-map-localization.service';

@NgModule({
  declarations: [],
  imports: [],
})
export class PolygonMapConfigModule {
  constructor(@Optional() @SkipSelf() parentModule?: PolygonMapConfigModule) {
    if (parentModule) {
      throw new Error(
        'PolygonMapConfigModule is already loaded. Import it in the AppModule only.'
      );
    }
  }

  static forRoot(
    config: PolygonMapConfig
  ): ModuleWithProviders<PolygonMapConfigModule> {
    config.translations = {
      ...polygonMapDefaultTranslations,
      ...config.translations,
    };
    return {
      ngModule: PolygonMapConfigModule,
      providers: [
        { provide: PolygonMapConfig, useValue: config },
        PolygonMapLocalizationService,
        PolygonMapLoadScriptService,
      ],
    };
  }
}
