import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import {
  BehaviorSubject,
  Observable,
  ReplaySubject,
  Subject,
  catchError,
  combineLatest,
  concat,
  map,
  of,
  shareReplay,
  switchMap,
  takeUntil,
  tap,
} from 'rxjs';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-trader-avatar',
  templateUrl: './trader-avatar.component.html',
  styleUrls: ['./trader-avatar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TraderAvatarComponent implements OnInit, OnDestroy {
  private _traderIdSubject = new ReplaySubject<string | number | undefined>(1);
  private _traderID?: number | string;
  private _destroyed$ = new Subject<void>();

  @Input()
  public get traderID(): number | string | undefined {
    return this._traderID;
  }
  public set traderID(v: number | string | undefined) {
    this._traderID = v;
    this._traderIdSubject.next(v);
  }

  private _imageInputSubject = new BehaviorSubject<string | null | undefined>(
    undefined
  );
  private _imageInput: string | null | undefined;
  @Input()
  public get image(): string | null | undefined {
    return this._imageInput;
  }
  public set image(v: string | null | undefined) {
    this._imageInput = v;
    this._imageInputSubject.next(v);
  }

  private _image$ = combineLatest([
    this._traderIdSubject,
    this._imageInputSubject,
  ]).pipe(
    takeUntil(this._destroyed$),
    switchMap(([traderID, image]) => {
      if (image != null) {
        return of(image);
      }
      return this._tryFetchAvatar(traderID);
    }),
    shareReplay({ bufferSize: 1, refCount: false })
  );
  state$: Observable<
    { type: 'loading' } | { type: 'loaded'; avatar: SafeUrl | null }
  > = concat(
    of({ type: 'loading' as 'loading' }),
    this._image$.pipe(map((v) => ({ type: 'loaded' as 'loaded', avatar: v })))
  ).pipe(
    takeUntil(this._destroyed$),
    shareReplay({ bufferSize: 1, refCount: false })
  );

  @Input()
  useNoCacheHeadersWhenRequestingAvatar = false;

  @Output()
  avatarLoaded = new EventEmitter<string | null | undefined>();

  constructor(private _http: HttpClient, private _sanitizer: DomSanitizer) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    this._destroyed$.next();
  }

  private _tryFetchAvatar(traderID: number | string | undefined) {
    const headers = this.useNoCacheHeadersWhenRequestingAvatar
      ? {
          'Cache-Control': 'no-cache, no-store, must-revalidate',
          Pragma: 'no-cache',
          Expires: 'Sat, 01 Jan 2000 00:00:00 GMT',
          'If-Modified-Since': '0',
        }
      : undefined;
    return this._http
      .get(`${environment.photoUrl}/trader-avatar/${traderID}`, {
        responseType: 'blob',
        headers,
      })
      .pipe(
        catchError((e) => of(null)),
        map((blob) => (blob != null ? URL.createObjectURL(blob) : null)),
        tap((url) => {
          this.avatarLoaded.next(url);
        }),
        map((url) =>
          url != null ? this._sanitizer.bypassSecurityTrustUrl(url) : null
        )
      );
  }
}
