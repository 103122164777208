<p-messages
  *ngIf="_errorMessage"
  styleClass="m-4"
  severity="error"
  [value]="[{ severity: 'error', summary: 'Error', detail: _errorMessage }]"
>
</p-messages>

<div class="password-reset-card max-w-22rem mx-auto">
  <header class="text-center flex flex-column gap-1 pb-4">
    <img
      class="mx-auto max-h-4rem"
      src="assets/ChecaChamba-icono-full-color.svg"
      alt=""
    />
    <h3>Cambia tu contraseña</h3>
    <p>Por favor completa el formulario para realizar el cambio de contraseña</p>
  </header>
  <form
    class="flex flex-column gap-5"
    [formGroup]="_passwordResetForm"
    (ngSubmit)="_resetPassword()"
  >
    <span>
      <span class="p-float-label">
        <input
          id="email"
          type="text"
          pInputText
          formControlName="email"
          class="w-full"
          autocomplete="username"
        />
        <label for="email">Email</label>
      </span>
      <small
        *ngIf="
                  _passwordResetForm.controls.email.invalid &&
                  _passwordResetForm.controls.email.errors?.['required'] &&
                  (_passwordResetForm.controls.email.touched || _submitted)
                "
        class="p-error"
        >Email es requerido</small
      >
    </span>
    <span>
      <span class="p-float-label">
        <p-password
          id="password"
          [feedback]="false"
          formControlName="password"
          [toggleMask]="true"
        >
        </p-password>
        <label for="password">Nueva Contraseña</label>
      </span>
      <small
        *ngIf="
                  _passwordResetForm.controls.password.invalid &&
                  _passwordResetForm.controls.password.errors?.['required'] &&
                  (_passwordResetForm.controls.password.touched || _submitted)
                "
        class="p-error"
        >Nueva contraseña es requerida</small
      >
      <app-password-strength-feedback
        class="mt-2"
        [value]="_passwordResetForm.controls.password.value"
      ></app-password-strength-feedback>
    </span>
    <span>
      <span class="p-float-label">
        <p-password
          id="verifyPassword"
          [feedback]="false"
          formControlName="verifyPassword"
          [toggleMask]="true"
        >
        </p-password>
        <label for="verifyPassword">Verificar Contraseña</label>
      </span>
      <small
        *ngIf="
                  _passwordResetForm.controls.verifyPassword.invalid &&
                  _passwordResetForm.controls.verifyPassword.errors?.['required'] &&
                  (_passwordResetForm.controls.verifyPassword.touched || _submitted)
                "
        class="p-error"
        >Se requiere verificar la contraseña</small
      >
    </span>
    <button
      *ngIf="!_passwordChanged"
      [disabled]="!_passwordChanged && (!_passwordResetForm.valid || _saving)"
      [loading]="_saving"
      label="Continuar"
      pButton
      type="submit"
    ></button>
    <div
      *ngIf="_passwordChanged"
      class="flex flex-column align-items-center gap-2"
    >
      <p-message
        severity="success"
        text="Tu contraseña ha cambiado"
      ></p-message>
      <a
        *ngIf="_signedIn$ | async; else returnToLogIn"
        [routerLink]="['/mi-perfil']"
        ><button pButton label="Regresar a mi perfil"></button
      ></a>
      <ng-template #returnToLogIn>
        <a [routerLink]="['/auth/login']"
          ><button pButton label="Regresar a registro"></button
        ></a>
      </ng-template>
    </div>
  </form>
</div>
