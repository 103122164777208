import { DateTime } from 'luxon';
import { OperatorFunction, of, tap } from 'rxjs';

export function cache<T>(key: string): OperatorFunction<T, T> {
  const cacheDurationHours = 1.0;

  const getCacheValueStorageKey = () => `cache_${key}_value`;
  const getCacheDateStorageKey = () => `cache_${key}_date`;

  return (source) => {
    try {
      const cacheValueStorageString = localStorage.getItem(
        getCacheValueStorageKey()
      );
      const cacheValue: T | null =
        cacheValueStorageString != null
          ? JSON.parse(cacheValueStorageString)
          : null;
      const cacheDateTimeStorageString = localStorage.getItem(
        getCacheDateStorageKey()
      );
      const cacheDateTime: DateTime | null =
        cacheDateTimeStorageString != null
          ? DateTime.fromISO(cacheDateTimeStorageString)
          : null;
      const cacheIsExpired =
        cacheDateTime == null ||
        cacheDateTime.diffNow('hours').hours <= -cacheDurationHours;

      if (cacheIsExpired || cacheValue == null) {
        return source.pipe(
          tap((v) => {
            localStorage.setItem(getCacheValueStorageKey(), JSON.stringify(v));
            localStorage.setItem(
              getCacheDateStorageKey(),
              DateTime.now().toISO()
            );
          })
        );
      } else {
        return of(cacheValue);
      }
    } catch (error) {
      console.error(`Error caching API results for ${key}`);
      return source;
    }
  };
}
