import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Subject, firstValueFrom, take, takeUntil } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { mapErrorToString } from '../shared/forms/error';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
  providers: [MessageService],
  host: {
    class: 'container max-w-22rem mt-4',
  },
})
export class SignInComponent implements OnInit, OnDestroy {
  _socialErrorMessage: string = '';
  _loggingIn = false;
  private _destroyed$ = new Subject<void>();
  _emailSignInForm = this._fb.group({
    email: ['', [Validators.required, Validators.email]],
    password: ['', Validators.required],
  });
  _signInEmailErrorMessage?: string;
  _submittedEmailSignIn = false;

  constructor(
    private _fb: FormBuilder,
    private _auth: AuthService,
    private _route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this._handleLoadEmailFromQueryParams();
    this._handleSocialAuthError();
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
  }

  _signInWithEmail() {
    if (!this._emailSignInForm.valid) {
      return;
    }
    const email = this._emailSignInForm.controls.email.value?.trim();
    const password = this._emailSignInForm.controls.password.value;
    if (email == null || password == null) {
      return;
    }
    this._signInEmailErrorMessage = undefined;
    this._loggingIn = true;
    this._submittedEmailSignIn = true;
    this._auth.signInEmail(email, password).subscribe({
      error: (error) => {
        this._signInEmailErrorMessage = mapErrorToString(
          error,
          'Error al iniciar sesión, verifica que tu email y contraseña sean correctos.'
        );
        this._loggingIn = false;
      },
    });
  }

  async _signInWithFacebook() {
    await firstValueFrom(this._auth.signInFacebook());
  }

  async _signInWithGoogle() {
    // handled automatically by auth service
  }

  private _handleLoadEmailFromQueryParams() {
    this._route.queryParamMap.pipe(take(1)).subscribe({
      next: (params) => {
        const email = params.get('email');
        if (email) {
          this._emailSignInForm.reset({ email });
        }
      },
    });
  }

  private _handleSocialAuthError() {
    this._auth.facebookAuthError$.pipe(takeUntil(this._destroyed$)).subscribe({
      next: (error) => (this._socialErrorMessage = error),
    });
    this._auth.googleAuthError$.pipe(takeUntil(this._destroyed$)).subscribe({
      next: (error) => (this._socialErrorMessage = error),
    });
  }
}
