import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddressSummaryComponent } from './address-summary.component';

@NgModule({
  declarations: [AddressSummaryComponent],
  imports: [CommonModule],
  exports: [AddressSummaryComponent],
})
export class AddressSummaryModule {}
