<p-toast position="top-center" key="get-contact-details-error"></p-toast>
<p-card *ngIf="trader">
  <div class="card-content">
    <header class="p-card-title name">
      <a
        [routerLink]="[
          linkTo === 'profile' ? '/profile' : '/mi-chamba',
          trader.traderID
        ]"
        class="name-title line-height-3"
        >{{ trader.name }}</a
      >
    </header>
    <div class="trader-buttons flex flex-row gap-2 flex-wrap">
      <button
        class="p-button-secondary p-button-sm align-self-start w-full sm:w-auto"
        *ngIf="!hideViewProfileButton"
        pButton
        label="Ver perfil"
        icon="pi pi-user"
        (click)="_viewProfile()"
      ></button>
      <button
        *ngIf="!hideContactDetails && _contactDetails == null"
        class="p-button-secondary p-button-sm align-self-start w-full sm:w-auto"
        pButton
        label="Ver datos de contacto"
        icon="pi pi-id-card"
        [loading]="_loadingContactDetails"
        (click)="_expandDetails($event)"
      ></button>
      <app-public-review-trader-button
        *ngIf="!hideReviewButton"
        class="w-full sm:w-auto"
        [traderID]="trader.traderID"
      ></app-public-review-trader-button>
    </div>

    <div
      *ngIf="_contactDetails"
      class="contact-details contact-details-summary"
    >
      <ng-container *ngIf="_contactDetails?.phoneNumbers?.length">
        <span>
          <p-button
            icon="pi pi-whatsapp text-2xl"
            (click)="_openWhatsApp()"
            styleClass="p-button-success p-button-rounded"
          ></p-button> </span
        ><span> </span>
      </ng-container>
      <ng-container *ngIf="_contactDetails?.phoneNumbers?.length">
        <span class="text-600"><i class="pi pi-phone"> </i> Teléfono: </span
        ><span>{{ _contactDetails.phoneNumbers }}</span>
      </ng-container>
      <ng-container *ngIf="_contactDetails?.emailAddress">
        <span class="text-600"><i class="pi pi-at"></i> Email: </span
        ><span
          ><a [href]="'mailto:' + _contactDetails.emailAddress" class="overflow-wrap">{{
            _contactDetails.emailAddress
          }}</a></span
        >
      </ng-container>
      <ng-container *ngIf="_contactDetails?.website">
        <span class="text-600"
          ><i class="pi pi-external-link"></i> Web:
        </span>
        <span
          ><app-external-link
            [url]="_contactDetails.website"
          ></app-external-link
        ></span>
      </ng-container>
      <ng-container *ngIf="_contactDetails?.address">
        <span class="text-600"><i class="pi pi-building"></i> Dirección: </span
        ><app-address-summary
          [address]="_contactDetails.address"
        ></app-address-summary>
      </ng-container>
      <ng-container *ngIf="_contactDetails?.reverseLookup">
        <span class="text-600"
          ><i class="pi pi-map-marker"></i> Ubicación: </span
        ><span>{{ _contactDetails.reverseLookup }}</span>
      </ng-container>
    </div>
    <!-- TODO: don't have description on brief... probably should if we want to display it, otherwise need to fetch it. -->
    <!-- <div class="description">{{ trader.description }}</div> -->
    <app-trader-avatar
      class="avatar"
      [traderID]="trader.traderID"
      [routerLink]="[
        linkTo === 'profile' ? '/profile' : '/mi-chamba',
        trader.traderID
      ]"
    ></app-trader-avatar>
    <app-rating-and-review-summary
      class="reviews"
      [trader]="trader"
    ></app-rating-and-review-summary>
    <!-- TODO: remove any -->
    <div class="description">
      {{ $any(trader).description
      }}<span *ngIf="$any(trader).description?.length === 147">...</span>
    </div>
    <app-trader-job-types-summary
      *ngIf="compact; else detailedJobTypes"
      class="job-types"
      [value]="trader.jobTypes | mapProfessionDetailsToJobTypeDetails"
    ></app-trader-job-types-summary>
    <ng-template #detailedJobTypes>
      <app-job-type-chips
        class="job-types"
        [disabled]="true"
        [value]="trader.jobTypes | mapProfessionDetailsToJobTypeDetails"
      ></app-job-type-chips>
    </ng-template>
  </div>
</p-card>
