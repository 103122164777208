import {
  Component,
  forwardRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-phone-number-input',
  templateUrl: './phone-number-input.component.html',
  styleUrls: ['./phone-number-input.component.scss'],
  standalone: true,
  imports: [ReactiveFormsModule, DropdownModule, InputTextModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PhoneNumberInputComponent),
      multi: true,
    },
  ],
})
export class PhoneNumberInputComponent
  implements OnInit, OnDestroy, ControlValueAccessor
{
  countryCodes: any[] = [{ name: 'MX +52', code: '52' }];

  @ViewChild('countryCodeDropdown', { read: NG_VALUE_ACCESSOR, static: true })
  countryCodeDropdown!: ControlValueAccessor;

  _countryCodeFormControl = new FormControl('');
  _phoneNumberFormControl = new FormControl('');

  private _onTouched: any;
  private _onChange: any;
  private _subscriptions = new Subscription();

  constructor() {}

  ngOnInit(): void {
    this._subscriptions.add(
      this._phoneNumberFormControl.valueChanges.subscribe((pn) => {
        const cc = this._countryCodeFormControl.value;
        this._internalSetValue(cc, pn);
      })
    );
  }

  ngOnDestroy(): void {
    this._subscriptions.unsubscribe();
  }

  writeValue(obj: any): void {
    const execMatch = /^(?<cc>\+*52)(?<pn>\d{1,14})$/.exec(obj);
    const cc = execMatch?.groups?.['cc'] ?? '52';
    const pn = execMatch?.groups?.['pn'] ?? '';
    if (cc) {
      this._countryCodeFormControl.setValue(cc, { emitEvent: false });
    } else {
      this._countryCodeFormControl.setValue(null, { emitEvent: false });
    }
    if (pn) {
      const phoneUtil = PhoneNumberUtil.getInstance();
      const phoneNumber = phoneUtil.parse(`${pn}`, 'MX');
      const phoneNumberE164Format = phoneUtil.format(
        phoneNumber,
        PhoneNumberFormat.E164
      );
      if (phoneNumberE164Format !== pn) {
        this._phoneNumberFormControl.setValue(pn, { emitEvent: false });
      } else {
        this._phoneNumberFormControl.setValue(pn, { emitEvent: false });
      }
    } else {
      this._phoneNumberFormControl.setValue(null, { emitEvent: false });
    }
  }

  registerOnChange(fn: any): void {
    this._onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this._countryCodeFormControl.disable();
      this._phoneNumberFormControl.disable();
    } else {
      this._countryCodeFormControl.enable();
      this._phoneNumberFormControl.enable();
    }
  }

  private _internalSetValue(cc: string | null, pn: string | null) {
    if (cc == null || pn == null || pn === '') {
      this._onChange?.(null);
      return;
    }
    const phoneUtil = PhoneNumberUtil.getInstance();
    const phoneNumber = phoneUtil.parse(`${pn}`, 'MX');
    const phoneNumberE164Format = phoneUtil.format(
      phoneNumber,
      PhoneNumberFormat.E164
    );
    this._onChange?.(phoneNumberE164Format);
  }
}
