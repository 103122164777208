import { Component, OnDestroy, OnInit } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { AuthService } from '../auth/auth.service';
import { Subject, first, takeUntil } from 'rxjs';

@Component({
  selector: 'app-sign-in-dialog',
  templateUrl: './sign-in-dialog.component.html',
  styleUrls: ['./sign-in-dialog.component.scss'],
})
export class SignInDialogComponent implements OnInit, OnDestroy {
  private _destroyed$ = new Subject<void>();

  constructor(
    private _auth: AuthService,
    private _dialogRef: DynamicDialogRef
  ) {}

  ngOnInit(): void {
    this._auth.signInRedirectMode = 'no redirect';
    this._handleCloseDialogWhenLoggedIn();
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._auth.signInRedirectMode = 'default';
  }

  private _handleCloseDialogWhenLoggedIn() {
    this._auth.afterRedirect$.pipe(takeUntil(this._destroyed$)).subscribe({
      next: () => {
        this._dialogRef.close(true);
      },
    });
  }
}
