import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, first, switchMap } from 'rxjs';
import { endpoint } from '../api/api.service';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(public _auth: AuthService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      request.headers.has('Authorization') ||
      request.url.startsWith(`${environment.apiUrl}/zero/authenticate/renew`)
    ) {
      return next.handle(request);
    }
    return this._auth.authInfo$.pipe(
      first(),
      switchMap((authInfo) => {
        const bearerToken = authInfo?.token;
        if (bearerToken && request.url.startsWith(endpoint)) {
          request = request.clone({
            setHeaders: {
              Authorization: `Bearer ${bearerToken}`,
            },
          });
        }
        return next.handle(request);
      })
    );
  }
}
