<div class="relative">
  <p-listbox
    [options]="groupedJobTypes"
    [group]="true"
    [metaKeySelection]="false"
    [filter]="true"
    [multiple]="true"
    [listStyle]="{ 'max-height': '20rem' }"
    [formControl]="fc"
    [filterBy]="'label,group'"
  >
  </p-listbox>
  <p-progressSpinner *ngIf="loading"></p-progressSpinner>
</div>
