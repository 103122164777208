import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { DOC_ORIENTATION, NgxImageCompressService } from 'ngx-image-compress';
import { catchError, from, map, Observable, of, switchMap } from 'rxjs';
import { ClientService } from 'src/app/core/client.service';
import { ApiService } from '../api.service';
import { cache } from '../cache-operator';
import {
  GlobalCoordinate,
  InternalTraderBrief,
  JobTypeDetail,
  LocationDetail,
  MyTraderDetail,
  Page,
  ProfessionDetail,
  TraderAddOptions,
  TraderAddResponse,
  TraderBrief,
  TraderContactInfo,
  TraderDetail,
  TraderModifyOptions,
  TraderSearchOptions,
  TradingZoneAddOptions,
  TradingZoneDetail,
} from './checachamba.model';

@Injectable({
  providedIn: 'root',
})
export class ChecachambaService {
  constructor(
    private _api: ApiService,
    private _recaptcha: ReCaptchaV3Service,
    private _client: ClientService,
    private _scaleImage: NgxImageCompressService
  ) {}

  public GetTraders(options: TraderSearchOptions) {
    options.clientID = this._client.getClientId();
    return this._api.Post<Page<TraderBrief>>(`cc/trader/search`, options);
  }

  public GetTraderContactInfo(traderID: number) {
    const clientID = this._client.getClientId();

    return this._recaptcha
      .execute('contactDetails')
      .pipe(
        switchMap((token) =>
          this._api.Get<TraderContactInfo>(
            `cc/trader/${traderID}/contact?clientID=${clientID}&reCaptchaToken=${token}`
          )
        )
      );
  }

  public TryGetTraders(
    options: TraderSearchOptions
  ): Observable<HttpResponse<Page<TraderBrief>>> {
    return this.GetTraders(options).pipe(
      catchError((e) => of(new HttpResponse<Page<TraderBrief>>()))
    );
  }

  public GetMyTraders() {
    return this._api.Get<TraderDetail[]>(`cc/trader`);
  }

  public GetTrader(traderID: number) {
    let url: string = `cc/trader/${traderID}`;
    return this._api.Get<TraderDetail>(url);
  }

  public GetMyTrader(traderID: number) {
    return this._api.Get<MyTraderDetail>(`cc/trader/${traderID}/mine`);
  }

  public AddTrader(options: TraderAddOptions) {
    return this._api.Post<TraderAddResponse>(`cc/trader`, options);
  }

  public ModifyTrader(traderID: number, options: TraderModifyOptions) {
    return this._api.Put<MyTraderDetail>(`cc/trader/${traderID}`, options);
  }

  public ModifyTraderAvatar(
    traderID: number,
    photoBase64: string,
    name: string
  ) {
    return from(
      this._scaleImage.compressFile(
        photoBase64,
        DOC_ORIENTATION.Default,
        100,
        50,
        198,
        198
      )
    ).pipe(
      switchMap((resizedImageUri) => from(fetch(resizedImageUri))),
      switchMap((response) => from(response.blob())),
      switchMap((blob) => from(blob.arrayBuffer())),
      map((ab) => new File([ab], name)),
      switchMap((file) => {
        const formData = new FormData();
        formData.append('photo', file);
        return this._api.FormPost<unknown>(
          `cc/trader/${traderID}/avatar`,
          formData
        );
      })
    );
  }

  public SetVisibility(traderID: number, visible: boolean) {
    return this._api.Put(`cc/trader/${traderID}/visibility?visible=${visible}`);
  }

  public GetProfessions(limit: number, offset: number = 0, search?: string) {
    let url: string = `cc/profession?limit=${limit}&offset=${offset}`;
    if (search) {
      url += `&search=${search}`;
    }
    return this._api.Get<Page<ProfessionDetail>>(url);
  }

  public GetJobTypes(limit: number, offset: number = 0, search?: string) {
    let url: string = `cc/job-type?limit=${limit}&offset=${offset}`;
    if (search) {
      url += `&search=${search}`;
    }
    return this._api.Get<Page<JobTypeDetail>>(url).pipe(cache(url));
  }

  /**Get a report of interactions with a trader's profile */
  public GetTraderInteractions(traderID: number, start: Date, end: Date) {
    let url: string = `interactions/trader/${traderID}/interaction?start=${start.toISOString()}&end=${end.toISOString()}`;
    return this._api.Get<
      {
        key: number;
        dates: { date: string; count: number }[];
      }[]
    >(url);
  }

  /**Get trading zones as squared in latitude & longitude */
  public GetTradingZonesForTrader(traderID: number) {
    return this._api.Get<Page<TradingZoneDetail>>(`cc/trader/${traderID}/zone`);
  }

  public AddTradingZone(traderID: number, options: TradingZoneAddOptions) {
    return this._api.Post<TradingZoneDetail>(
      `cc/trader/${traderID}/zone`,
      options
    );
  }

  public UpdateTradingZone(
    traderID: number,
    zoneID: string,
    options: TradingZoneAddOptions
  ) {
    return this._api.Put<TradingZoneDetail>(
      `cc/trader/${traderID}/zone/${zoneID}`,
      options
    );
  }

  public RemoveTradingZone(traderID: number, tradingZoneID: number) {
    return this._api.Delete(`cc/trader/${traderID}/zone/${tradingZoneID}`);
  }

  public ResetZone(traderID: number, zoneID: string, lat: number, lon: number) {
    return this._api.Put(
      `cc/trader/${traderID}/zone/${zoneID}/reset?lat=${lat}&lon=${lon}`
    );
  }

  public GetTraderPortfolio(traderID: number) {
    return this._api.Get<string[]>(`cc/trader/${traderID}/portfolio`);
  }

  public AddPortfolioItem(traderID: number, file: File) {
    const formData = new FormData();
    formData.append('photo', file);
    return this._api.FormPost(`cc/trader/${traderID}/portfolio`, formData);
  }

  public RemovePortfolioItem(traderID: number, filename: string) {
    return this._api.Delete(`cc/trader/${traderID}/portfolio/${filename}`);
  }

  public GetCoordinatesFromPostcode(postcode: string) {
    return this._api.Get<GlobalCoordinate>(
      `cc/atlas/postcode/${postcode}/coordinates`
    );
  }

  public GetAddress(number: string, road: string, postcode: string) {
    return this._api.Get<LocationDetail[]>(
      `cc/atlas/address?number=${number}&road=${road}&postcode=${postcode}`
    );
  }

  public ReverseLookup(lon: number, lat: number) {
    return this._api.Get<{ description: string }>(
      `cc/atlas/geocode?lat=${lat}&lon=${lon}`
    );
  }

  public AddressSearch(query: string) {
    return this._api.Get<LocationDetail[]>(`cc/atlas/address/${query}`);
  }

  public TryAddressSearch(query: string) {
    return this.AddressSearch(query).pipe(catchError((e) => of(null)));
  }

  public LocaleSearch(query: string) {
    return this._api.Get<LocationDetail[]>(`cc/atlas/search/${query}`);
  }

  public TryLocaleSearch(query: string) {
    return this.LocaleSearch(query).pipe(catchError((e) => of(null)));
  }




  //internal calls
  public GetInternalTraders(limit: number, offset: number)
  {
    return this._api.Get<Page<InternalTraderBrief>>(`cc/internal/trader?limit=${limit}&offset=${offset}`);
  }

  public GetInternalTrader(traderid: number)
  {
    return this._api.Get<MyTraderDetail>(`cc/internal/trader/${traderid}`);
  }


}
