import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { map as _map } from 'lodash-es';
import { ButtonModule } from 'primeng/button';
import { ChipModule } from 'primeng/chip';
import { DialogModule } from 'primeng/dialog';
import { ReplaySubject, map } from 'rxjs';
import { JobTypeDetail } from 'src/app/api/checachamba/checachamba.model';

@Component({
  selector: 'app-trader-job-types-summary',
  standalone: true,
  imports: [CommonModule, ChipModule, DialogModule, ButtonModule],
  templateUrl: './trader-job-types-summary.component.html',
  styleUrls: ['./trader-job-types-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TraderJobTypesSummaryComponent implements OnInit {
  private _value: JobTypeDetail[] = [];

  @Input()
  public set value(v: JobTypeDetail[]) {
    this._value = v;
    this._valueSubject.next(v);
  }

  public get value(): JobTypeDetail[] {
    return this._value;
  }

  private _valueSubject = new ReplaySubject<JobTypeDetail[]>(1);
  private _value$ = this._valueSubject.asObservable();
  _jobTypes$ = this._value$.pipe(
    map((jobTypes) =>
      _map(jobTypes, (jobType, i) => ({
        ...jobType,
        visible: i < this._maxDisplayedJobTypes,
      }))
    )
  );

  _maxDisplayedJobTypes = 5;

  constructor() {}

  ngOnInit(): void {}
}
