import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { first, firstValueFrom } from 'rxjs';
import { ZeroService } from 'src/app/api/zero/zero.service';
import { AuthService } from 'src/app/auth/auth.service';
import { mapErrorToString } from 'src/app/shared/forms/error';
import { ChValidators } from 'src/app/shared/forms/validators';

@Component({
  selector: 'app-change-phone-dialog',
  templateUrl: './change-phone-dialog.component.html',
  styleUrls: ['./change-phone-dialog.component.scss'],
})
export class ChangePhoneDialogComponent implements OnInit {
  @Input()
  visible = false;

  @Output()
  visibleChange = new EventEmitter<boolean>();

  _sendChangePhoneCodeForm = this._fb.group({
    currentPhone: this._fb.control<string>({ value: '', disabled: true }),
    newPhone: ['', [Validators.required, ChValidators.phoneNumber]],
  });
  _sendChangePhoneCodeFormSubmitted = false;
  _savingSendChangePhoneCode = false;
  _sendChangePhoneCodeErrorMessage?: string;

  _enterChangePhoneCode = false;

  _changePhoneForm = this._fb.group({
    newPhone: ['', [Validators.required]],
    code: ['', Validators.required],
  });
  _changePhoneFormSubmitted = false;
  _savingChangePhoneForm = false;
  _changePhoneFormErrorMessage?: string;

  _phoneChanged = false;

  constructor(
    private _fb: FormBuilder,
    private _auth: AuthService,
    private _zero: ZeroService
  ) {}

  ngOnInit(): void {
    this._auth.user$.pipe(first()).subscribe({
      next: (user) => {
        if (user) {
          this._sendChangePhoneCodeForm.reset({
            currentPhone: user.info?.phoneNumber ?? '(No hay número de teléfono registrado)',
          });
        }
      },
    });
  }

  async _sendChangePhoneCode() {
    this._sendChangePhoneCodeForm.updateValueAndValidity();
    this._sendChangePhoneCodeFormSubmitted = true;
    if (!this._sendChangePhoneCodeForm.valid) {
      return;
    }

    const newPhone = this._sendChangePhoneCodeForm.value.newPhone;

    if (newPhone == null || newPhone.length === 0) {
      return;
    }

    try {
      this._savingSendChangePhoneCode = true;
      const response = await firstValueFrom(
        this._zero.sendChangePhoneCode(newPhone)
      );
      this._changePhoneForm.reset({ newPhone });
      this._enterChangePhoneCode = true;
    } catch (error) {
      if (error instanceof HttpErrorResponse && error.status === 409) {
        this._sendChangePhoneCodeErrorMessage = mapErrorToString(
          error,
          'Este número ya está en uso.'
        );
      } else {
        this._sendChangePhoneCodeErrorMessage = mapErrorToString(
          error,
          'Error al enviar el código.'
        );
      }
    } finally {
      this._savingSendChangePhoneCode = false;
    }
  }

  async _submitChangePhoneCode() {
    this._changePhoneForm.updateValueAndValidity();
    this._changePhoneFormSubmitted = true;

    if (!this._changePhoneForm.valid) {
      return;
    }

    const newPhone = this._changePhoneForm.value.newPhone;
    const code = this._changePhoneForm.value.code;

    if (newPhone == null || code == null) {
      return;
    }

    try {
      this._savingChangePhoneForm = true;
      const response = await firstValueFrom(
        this._auth.changePhone(newPhone, code)
      );
      this._phoneChanged = true;
    } catch (error) {
      if (error instanceof HttpErrorResponse && error.status === 409) {
        this._changePhoneFormErrorMessage = mapErrorToString(
          error,
          'Este número ya está en uso.'
        );
      } else {
        this._changePhoneFormErrorMessage = mapErrorToString(
          error,
          'Error al cambiar el número.'
        );
      }
    } finally {
      this._savingChangePhoneForm = false;
    }
  }
}
