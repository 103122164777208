import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, catchError, firstValueFrom } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable()
export class RedirectOnUnauthorizedInterceptor implements HttpInterceptor {
  constructor(private _auth: AuthService, private _router: Router) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError(async (err) => {
        if (
          err instanceof HttpErrorResponse &&
          [401, 403].some((status) => err.status === status)
        ) {
          const isSignedIn = await firstValueFrom(this._auth.signedIn$);
          if (isSignedIn) {
            this._router.navigate(['mi-perfil']);
          } else {
            this._auth.signOut();
            this._router.navigate(['auth/login']);
          }
        }
        throw err;
      })
    );
  }
}
