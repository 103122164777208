<p-messages
  *ngIf="_errorMessage"
  styleClass="m-4"
  severity="error"
  [value]="[{ severity: 'error', summary: 'Error', detail: _errorMessage }]"
>
</p-messages>
<div class="password-reset-card max-w-22rem mx-auto">
  <header class="text-center flex flex-column gap-1 pb-4">
    <img
      class="mx-auto max-h-4rem"
      src="assets/ChecaChamba-icono-full-color.svg"
      alt=""
    />
    <h3>Completa tu registro</h3>
    <p>Por favor completa el formulario para terminar de crear tu cuenta.</p>
  </header>
  <form
    class="flex flex-column gap-5"
    [formGroup]="_passwordResetForm"
    (ngSubmit)="_signUp()"
  >
    <span>
      <span class="p-float-label">
        <input
          id="email"
          type="text"
          pInputText
          formControlName="email"
          class="w-full"
        />
        <label for="email">Email</label>
      </span>
      <small
        *ngIf="
                  _passwordResetForm.controls.email.invalid &&
                  _passwordResetForm.controls.email.errors?.['required'] &&
                  (_passwordResetForm.controls.email.touched || _submitted)
                "
        class="p-error"
        >Email es requerido</small
      >
    </span>
    <span>
      <span class="p-float-label">
        <input
          id="name"
          type="text"
          pInputText
          formControlName="name"
          class="w-full"
        />
        <label for="name">Nombre</label>
      </span>
      <small
        *ngIf="
                  _passwordResetForm.controls.name.invalid &&
                  _passwordResetForm.controls.name.errors?.['required'] &&
                  (_passwordResetForm.controls.name.touched || _submitted)
                "
        class="p-error"
        >El nombre es requerido</small
      >
    </span>
    <span>
      <span class="p-float-label">
        <p-password
          id="password"
          formControlName="password"
          [feedback]="false"
          [toggleMask]="true"
        ></p-password>
        <label for="password">Contraseña</label>
      </span>
      <small
        *ngIf="
                  _passwordResetForm.controls.password.invalid &&
                  _passwordResetForm.controls.password.errors?.['required'] &&
                  (_passwordResetForm.controls.password.touched || _submitted)
                "
        class="p-error"
        >La contraseña es requerida</small
      >
      <app-password-strength-feedback
        class="mt-2"
        [value]="_passwordResetForm.controls.password.value"
      ></app-password-strength-feedback>
    </span>
    <span>
      <span class="p-float-label">
        <p-password
          id="verifyPassword"
          [feedback]="false"
          formControlName="verifyPassword"
          [toggleMask]="true"
        ></p-password>
        <label for="verifyPassword">Verificar Contraseña</label>
      </span>
      <small
        *ngIf="
                _passwordResetForm.controls.verifyPassword.invalid &&
                _passwordResetForm.controls.verifyPassword.errors?.['required'] &&
                (_passwordResetForm.controls.verifyPassword.touched || _submitted)
              "
        class="p-error"
        >Se requiere la verificación de la contraseña</small
      >
      <small
        *ngIf="_passwordResetForm.errors?.['doesNotMatch'] &&
                ((_passwordResetForm.controls.verifyPassword.value?.length ?? 0 ) > 0 )"
        class="p-error"
        >Las contraseñas deben coincidir</small
      >
    </span>
    <button
      [disabled]="!_passwordResetForm.valid || _saving"
      [loading]="_saving"
      label="Terminar registro"
      pButton
      type="submit"
    ></button>
  </form>
</div>
