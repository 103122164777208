<p-dialog
  [modal]="true"
  [draggable]="false"
  header="Cambiar número de teléfono"
  [style]="{ width: '5in' }"
  [visible]="visible"
  (visibleChange)="visibleChange.emit($event)"
>
  <form *ngIf="!_enterChangePhoneCode" [formGroup]="_sendChangePhoneCodeForm">
    <div class="field">
      <label for="phone" class="block">Teléfono Actual</label>
      <input
        class="w-full"
        id="phone"
        type="username"
        formControlName="currentPhone"
        pInputText
      />
    </div>
    <div class="field mb-0">
      <label for="phone" class="block">Nuevo Teléfono</label>
      <app-phone-number-input
        class="w-full"
        id="phone"
        formControlName="newPhone"
      ></app-phone-number-input>
      <small
        *ngIf="
          _sendChangePhoneCodeForm.controls.newPhone.invalid &&
          _sendChangePhoneCodeForm.controls.newPhone.errors?.['required'] &&
          (_sendChangePhoneCodeForm.controls.newPhone.dirty ||
            _sendChangePhoneCodeFormSubmitted)
        "
        class="p-error"
        >Teléfono requerido.</small
      >
      <small
        *ngIf="
          _sendChangePhoneCodeForm.controls.newPhone.invalid &&
          _sendChangePhoneCodeForm.controls.newPhone.errors?.['phonenumber'] &&
          (_sendChangePhoneCodeForm.controls.newPhone.dirty ||
            _sendChangePhoneCodeFormSubmitted)
        "
        class="p-error"
        >Por favor ingresa un número válido.</small
      >
    </div>
    <p-message
      *ngIf="_sendChangePhoneCodeErrorMessage"
      severity="error"
      styleClass="w-full mt-2"
      [text]="_sendChangePhoneCodeErrorMessage"
    ></p-message>
  </form>
  <form
    *ngIf="_enterChangePhoneCode && !_phoneChanged"
    [formGroup]="_changePhoneForm"
  >
    <div class="field mb-0">
      <label for="phone" class="block">Enter Code</label>
      <input pInputText formControlName="code" class="w-full" />
      <small
        *ngIf="
          _changePhoneForm.controls.code.invalid &&
          _changePhoneForm.controls.code.errors?.['required'] &&
          (_changePhoneForm.controls.code.dirty ||
            _changePhoneFormSubmitted)
        "
        class="p-error"
        >Teléfono es requerido</small
      >
      <small
        *ngIf="
          _changePhoneForm.controls.newPhone.invalid &&
          _changePhoneForm.controls.newPhone.errors?.['phonenumber'] &&
          (_changePhoneForm.controls.newPhone.dirty ||
            _changePhoneFormSubmitted)
        "
        class="p-error"
        >Por favor ingresa un número válido.</small
      >
    </div>
    <p-message
      *ngIf="_changePhoneFormErrorMessage"
      severity="error"
      styleClass="w-full mt-2"
      [text]="_changePhoneFormErrorMessage"
    ></p-message>
  </form>

  <p-message
    *ngIf="_phoneChanged"
    styleClass="w-full"
    severity="success"
    text="Tú teléfono ha sido cambiado con éxito."
  ></p-message>

  <ng-template pTemplate="footer">
    <button
      *ngIf="!_enterChangePhoneCode"
      pButton
      label="Enviar Código"
      type="submit"
      [loading]="_savingSendChangePhoneCode"
      (click)="_sendChangePhoneCode()"
    ></button>
    <button
      *ngIf="_enterChangePhoneCode"
      pButton
      label="Enviar Código"
      type="submit"
      [loading]="_savingChangePhoneForm"
      (click)="_submitChangePhoneCode()"
    ></button>
  </ng-template>
</p-dialog>
