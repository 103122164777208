import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { mapErrorToString } from '../shared/forms/error';

@Injectable({
  providedIn: 'root',
})
export class HandleErrorService {
  constructor(private _message: MessageService) {}

  handleError(error: Error | string) {
    this._message.add({
      severity: 'error',
      closable: true,
      life: 7500,
      summary: 'Error',
      detail: typeof error === 'string' ? error : mapErrorToString(error),
      key: 'error',
    });
  }
}
