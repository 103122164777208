// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  GMAP_API_KEY: 'AIzaSyD5O1lj3LmNKfUBDaid2qxyrwE_G9BXu-g',
  reCaptchaSiteKey: '6LdskNwkAAAAAGJ7ByprwQwj6J3O_4zD2IbhrzLS',
  googleClientId:
    '895740280106-5stcclr0e1u247du7lbvnpn6drrhtujb.apps.googleusercontent.com',
  facebookClientId: '8555054341235494',
  maxTradersPerUser: 1,
  apiUrl: 'https://api-wessex.checachamba.mx',
  photoUrl: 'https://checachambastagingphotos.blob.core.windows.net',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
