import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { firstValueFrom } from 'rxjs';
import { ZeroService } from '../api/zero/zero.service';
import { AuthService } from '../auth/auth.service';
import { mapErrorToString } from '../shared/forms/error';
import { ChValidators } from '../shared/forms/validators';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { HandleErrorService } from '../core/handle-error.service';
import { TraderDetail } from '../api/checachamba/checachamba.model';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
  host: {
    class: 'container',
  },
})
export class UserProfileComponent implements OnInit, OnDestroy {
  user$ = this._auth.user$;

  _loading = false;
  _sendingEmail = false;
  _emailSent = false;

  _editingEmail = false;
  _editingPhone = false;

  _unlinkingSocial?: string;

  _googleAuthError$ = this._auth.googleAuthError$;
  _facebookAuthError$ = this._auth.facebookAuthError$;

  constructor(
    private _auth: AuthService,
    private _zero: ZeroService,
    private _router: Router,
    private _handleError: HandleErrorService
  ) {}

  ngOnInit(): void {
    this._auth.socialSignInMode = 'linking';
  }

  ngOnDestroy(): void {
    this._auth.socialSignInMode = 'default';
  }

  trackByTraderID = (index: any, trader: TraderDetail) => trader.traderID;

  async _changeEmail() {
    this._editingEmail = true;
  }

  async _changePhone() {
    this._editingPhone = true;
  }

  async _sendChangePasswordEmail() {
    const email = (await firstValueFrom(this.user$))?.info?.emailAddress;
    if (email == null) {
      return;
    }
    try {
      this._sendingEmail = true;
      await firstValueFrom(this._zero.sendEmailAuthCode(email));
      this._emailSent = true;
    } catch (error) {
      if (error instanceof HttpErrorResponse) {
        if (error.status === 429) {
          this._handleError.handleError('Conflicto en la petición HTTP.');
          return;
        } else if (error.status === 409) {
          this._handleError.handleError(
            'Conflicto en la petición HTTP. Por favor, espera 15 minutos y vuélvelo a intentar.  '
          );
          return;
        }
      }
      this._handleError.handleError(
        mapErrorToString(error, 'Error al cambiar la contraseña')
      );
    } finally {
      this._sendingEmail = false;
    }
  }

  async _linkFacebook() {
    await firstValueFrom(this._auth.linkFacebook());
  }

  async _registerTrader() {
    this._router.navigate(['/onboard']);
  }

  async _unlinkSocial(social: string) {
    try {
      this._unlinkingSocial = social;
      switch (social) {
        case 'google':
          await firstValueFrom(this._auth.unlinkGoogle());
          break;
        case 'facebook':
          await firstValueFrom(this._auth.unlinkFacebook());
          break;
      }
    } catch (error) {
      if (error instanceof HttpErrorResponse) {
        switch (error.status) {
          case 404:
            this._handleError.handleError(
              `No se encontro ninguna red social vinculada a ${social}`
            );
            return;
          case 409:
            this._handleError.handleError(
              `No se puede desvincular la cuenta de ${social}. Debes tener al menos un email, número de teléfono o red social registrada.`
            );
            return;
        }
      }
      this._handleError.handleError(
        mapErrorToString(error, 'No fue posible desvincular la cuenta.')
      );
    } finally {
      this._unlinkingSocial = undefined;
    }
  }
}
