<header class="text-center flex flex-column gap-1 pb-4">
  <img
    class="mx-auto max-h-4rem"
    src="assets/ChecaChamba-icono-full-color.svg"
    alt=""
  />
  <h3>Entrar a tu cuenta</h3>
</header>
<asl-google-signin-button
  size="large"
  text="continue_with"
  type="standard"
  width="350"
></asl-google-signin-button>
<app-facebook-login-button
  class="w-full"
  (click)="_signInWithFacebook()"
></app-facebook-login-button>
<p-message
  *ngIf="_socialErrorMessage"
  severity="error"
  styleClass="w-full"
  [text]="_socialErrorMessage"
></p-message>
<p-divider align="center">O</p-divider>
<form
  class="flex flex-column gap-5"
  [formGroup]="_emailSignInForm"
  (ngSubmit)="_signInWithEmail()"
>
  <span class="form-field">
    <span class="p-float-label">
      <input
        id="email"
        type="text"
        pInputText
        formControlName="email"
        class="w-full"
        autocomplete="username"
      />
      <label for="email">Email</label>
    </span>
    <small
      *ngIf="
                _emailSignInForm.controls.email.invalid &&
                _emailSignInForm.controls.email.errors?.['required'] &&
                (_emailSignInForm.controls.email.touched || _submittedEmailSignIn)
              "
      class="p-error"
      >Email es requerido</small
    >
    <small
      *ngIf="
                _emailSignInForm.controls.email.invalid &&
                _emailSignInForm.controls.email.errors?.['email'] &&
                (_emailSignInForm.controls.email.touched || _submittedEmailSignIn)
              "
      class="p-error"
      >Por favor ingresa un correo electrónico.</small
    >
  </span>
  <span class="form-field">
    <span class="p-float-label">
      <p-password
        [feedback]="false"
        formControlName="password"
        [toggleMask]="true"
      ></p-password>
      <label for="password">Contraseña</label>
    </span>
    <small
      *ngIf="
              _emailSignInForm.controls.password.invalid &&
              _emailSignInForm.controls.password.errors?.['required'] &&
              (_emailSignInForm.controls.password.touched || _submittedEmailSignIn)
            "
      class="p-error"
      >La contraseña es requerida</small
    >
    <small><a [routerLink]="['/password']">¿Olvidaste contraseña?</a></small>
  </span>
  <button
    pButton
    label="Iniciar sesión"
    [disabled]="!_emailSignInForm.valid"
    [loading]="_loggingIn"
    type="submit"
  ></button>
  <p-message
    [severity]="'error'"
    [text]="_signInEmailErrorMessage"
    *ngIf="_signInEmailErrorMessage"
  ></p-message>
</form>
<small class="text-center"
  >¿No eres un miembro? <a [routerLink]="['/auth/registrar']">Registrate</a></small
>
