import {
  FacebookLoginProvider,
  GoogleLoginProvider,
} from '@abacritt/angularx-social-login';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Subject, filter, firstValueFrom, takeUntil } from 'rxjs';
import { ZeroService } from '../api/zero/zero.service';
import { AuthService } from '../auth/auth.service';
import { mapErrorToString } from '../shared/forms/error';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
  host: {
    class: 'container block mt-4',
  },
})
export class SignUpComponent implements OnInit, OnDestroy {
  _socialErrorMessage: string = '';
  _emailSignUpForm = this._fb.group({
    email: ['', [Validators.required, Validators.email]],
  });
  _errorMessage?: string;
  _submitted = false;
  _sendingEmail = false;
  _emailSent = false;
  _loggingIn = true;
  private _destroyed$ = new Subject<void>();

  constructor(
    private _fb: FormBuilder,
    private _zero: ZeroService,
    private _auth: AuthService
  ) {}

  ngOnInit(): void {
    this._handleSocialAuthError();
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
  }

  async _signUpWithGoogle(token: string) {
    // handled automatically by auth service
  }

  async _signUpWithFacebook() {
    try {
      this._beginLoggingIn();
      await firstValueFrom(this._auth.signInFacebook());
    } finally {
      this._endLoggingIn();
    }
  }

  async _signUpWithEmail() {
    if (!this._emailSignUpForm.valid) {
      return;
    }
    const email = this._emailSignUpForm.value.email?.trim();
    if (email == null) {
      return;
    }
    try {
      this._submitted = true;
      this._sendingEmail = true;
      await firstValueFrom(this._zero.sendEmailSignUpVerificatinEmail(email));
      this._emailSent = true;
      this._emailSignUpForm.disable();
    } catch (error) {
      if (error instanceof HttpErrorResponse) {
        if (error.status === 429) {
          this._errorMessage = 'Demasiadas peticiones HTTP.';
          return;
        } else if (error.status === 409) {
          this._errorMessage =
            'Demasiadas peticiones HTTP. Por favor espera 15 minutos e intentalo de nuevo.';
          return;
        }
      }
      this._errorMessage = mapErrorToString(
        error,
        'Error al completar el registro. Por favor intentalo de nuevo más tarde.'
      );
    } finally {
      this._sendingEmail = false;
    }
  }

  private _beginLoggingIn() {
    this._loggingIn = true;
  }

  private _endLoggingIn() {
    this._loggingIn = false;
  }

  private _handleSocialAuthError() {
    this._auth.facebookAuthError$.pipe(takeUntil(this._destroyed$)).subscribe({
      next: (error) => (this._socialErrorMessage = error),
    });
    this._auth.googleAuthError$.pipe(takeUntil(this._destroyed$)).subscribe({
      next: (error) => (this._socialErrorMessage = error),
    });
  }
}
