import { Component, Input, OnInit } from '@angular/core';
import {
  TraderBrief,
  TraderDetail,
} from 'src/app/api/checachamba/checachamba.model';

@Component({
  selector: 'app-rating-and-review-summary',
  templateUrl: './rating-and-review-summary.component.html',
  styleUrls: ['./rating-and-review-summary.component.scss'],
})
export class RatingAndReviewSummaryComponent implements OnInit {
  @Input()
  trader?: TraderBrief;

  constructor() {}

  ngOnInit(): void {}
}
