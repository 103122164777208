import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { first, firstValueFrom } from 'rxjs';
import { ZeroService } from 'src/app/api/zero/zero.service';
import { AuthService } from 'src/app/auth/auth.service';
import { mapErrorToString } from 'src/app/shared/forms/error';

@Component({
  selector: 'app-change-email-dialog',
  templateUrl: './change-email-dialog.component.html',
  styleUrls: ['./change-email-dialog.component.scss'],
})
export class ChangeEmailDialogComponent implements OnInit {
  @Input()
  visible = false;

  @Output()
  visibleChange = new EventEmitter<boolean>();

  _sendChangeEmailCodeForm = this._fb.group({
    currentEmail: this._fb.control<string>({ value: '', disabled: true }),
    newEmail: ['', [Validators.required, Validators.email]],
  });
  _sendChangeEmailCodeFormSubmitted = false;
  _savingSendChangeEmailCode = false;
  _sendChangeEmailCodeErrorMessage?: string;

  _verificationSent = false;

  // _changeEmailForm = this._fb.group({
  //   newEmail: ['', [Validators.required]],
  //   code: ['', Validators.required],
  // });
  // _changeEmailFormSubmitted = false;
  // _savingChangeEmailForm = false;
  // _changeEmailFormErrorMessage?: string;

  constructor(
    private _fb: FormBuilder,
    private _auth: AuthService,
    private _zero: ZeroService
  ) {}

  ngOnInit(): void {
    this._auth.user$.pipe(first()).subscribe({
      next: (user) => {
        if (user) {
          this._sendChangeEmailCodeForm.reset({
            currentEmail: user.info?.emailAddress ?? '(No hay email)',
          });
        }
      },
    });
  }

  async _sendChangeEmailCode() {
    this._sendChangeEmailCodeForm.updateValueAndValidity();
    this._sendChangeEmailCodeFormSubmitted = true;

    if (!this._sendChangeEmailCodeForm.valid) {
      return;
    }

    const newEmail = this._sendChangeEmailCodeForm.value.newEmail;

    if (newEmail == null || newEmail.length === 0) {
      return;
    }

    try {
      this._savingSendChangeEmailCode = true;
      await firstValueFrom(this._zero.sendChangeEmailCode(newEmail));
      // this._changeEmailForm.reset({
      //   newEmail,
      // });
      this._verificationSent = true;
    } catch (error) {
      if (error instanceof HttpErrorResponse && error.status === 409) {
        this._sendChangeEmailCodeErrorMessage = mapErrorToString(
          error,
          'El email ya está en uso.'
        );
      } else {
        this._sendChangeEmailCodeErrorMessage = mapErrorToString(
          error,
          'Error al enviar el código.'
        );
      }
    } finally {
      this._savingSendChangeEmailCode = false;
    }
  }

  // async _submitChangeEmailCode() {
  //   this._changeEmailForm.updateValueAndValidity();
  //   this._changeEmailFormSubmitted = true;

  //   if (!this._changeEmailForm.valid) {
  //     return;
  //   }

  //   const newEmail = this._changeEmailForm.value.newEmail;
  //   const code = this._changeEmailForm.value.code;

  //   if (newEmail == null || code == null) {
  //     return;
  //   }

  //   try {
  //     this._savingChangeEmailForm = true;
  //     const response = await firstValueFrom(
  //       this._auth.changeEmail(newEmail, code)
  //     );
  //   } catch (error) {
  //     if (error instanceof HttpErrorResponse && error.status === 409) {
  //       this._changeEmailFormErrorMessage = mapErrorToString(
  //         error,
  //         'Email is already in use.'
  //       );
  //     } else {
  //       this._changeEmailFormErrorMessage = mapErrorToString(
  //         error,
  //         'Error changing email.'
  //       );
  //     }
  //   } finally {
  //     this._savingChangeEmailForm = false;
  //   }
  // }
}
