import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FacebookLoginButtonComponent } from './facebook-login-button.component';

@NgModule({
  declarations: [FacebookLoginButtonComponent],
  imports: [CommonModule],
  exports: [FacebookLoginButtonComponent],
})
export class FacebookLoginButtonModule {}
