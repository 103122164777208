import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from 'src/environments/environment';

export const endpoint: string = `${environment.apiUrl}/`;

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient) {}

  public Get<T>(resource: string): Observable<HttpResponse<T>> {
    let url = endpoint + resource;
    let contentHeaders: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http
      .get<T>(url, { observe: 'response', headers: contentHeaders })
      .pipe(
        map((res: HttpResponse<T>) => {
          return res;
        })
      );
  }

  public Post<T>(
    resource: string,
    data?: any,
    headers?: { [name: string]: string | string[] }
  ): Observable<HttpResponse<T>> {
    let url = endpoint + resource;
    let contentHeaders: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
      ...headers,
    });
    return this.http
      .post<T>(url, data, { observe: 'response', headers: contentHeaders })
      .pipe(
        map((res: HttpResponse<T>) => {
          return res;
        })
      );
  }

  public FormPost<T>(
    resource: string,
    data?: any,
    headers?: { [name: string]: string | string[] }
  ): Observable<HttpResponse<T>> {
    let url = endpoint + resource;
    let contentHeaders: HttpHeaders = new HttpHeaders({
      ...headers,
    });
    return this.http
      .post<T>(url, data, { observe: 'response', headers: contentHeaders })
      .pipe(
        map((res: HttpResponse<T>) => {
          return res;
        })
      );
  }

  public Put<T>(resource: string, data?: any): Observable<HttpResponse<T>> {
    let url = endpoint + resource;

    return this.http.put<T>(url, data, { observe: 'response' }).pipe(
      map((res) => {
        return res;
      })
    );
  }

  public Delete<T>(resource: string) {
    let url = endpoint + resource;
    let contentHeaders: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    return this.http.delete<T>(url, { observe: 'response' }).pipe(
      map((res) => {
        return res;
      })
    );
  }

  reportError<T>(err: HttpErrorResponse, obs: Observable<T>) {
    console.log(err);
    if (err.status == 500) {
      //TODO: Get Error Code
    } else if (err.status > 500) {
      //Server error
    }

    return obs;
  }
}
