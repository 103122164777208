import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { firstValueFrom } from 'rxjs';
import { ZeroService } from '../api/zero/zero.service';
import { mapErrorToString } from '../shared/forms/error';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss'],
  host: {
    class: 'container mt-4 block',
  },
})
export class PasswordComponent implements OnInit {
  _emailResetForm = this._fb.group({
    email: ['', [Validators.required, Validators.email]],
  });
  _errorMessage?: string;
  _submitted = false;
  _sendingEmail = false;
  _emailSent = false;

  constructor(private _fb: FormBuilder, private _zero: ZeroService) {}

  ngOnInit(): void {}

  async _sendPasswordResetEmail() {
    if (!this._emailResetForm.valid) {
      return;
    }
    const email = this._emailResetForm.value.email;
    if (email == null) {
      return;
    }
    try {
      this._submitted = true;
      this._sendingEmail = true;
      await firstValueFrom(this._zero.sendEmailAuthCode(email));
      this._emailSent = true;
    } catch (error) {
      if (error instanceof HttpErrorResponse) {
        if (error.status === 429) {
          this._errorMessage = 'Conflicto en la petición HTTP.';
          return;
        } else if (error.status === 409) {
          this._errorMessage =
            'Conflicto en la petición HTTP. Por favor, espera 15 minutos y vuélvelo a intentar.';
          return;
        }
      }
      this._errorMessage = mapErrorToString(
        error,
        'Error al cambiar la contraseña.'
      );
    } finally {
      this._sendingEmail = false;
    }
  }
}
