<div *ngIf="address">
  <div>{{ address.street }} {{ address.externalNumber }}</div>
  <ng-container *ngIf="address.municipalitySubdivision && address.municipality">
    {{ address.municipalitySubdivision }},
    {{ address.municipality }}
  </ng-container>
  <ng-container
    *ngIf="address.municipalitySubdivision && !address.municipality"
  >
    {{ address.municipalitySubdivision }}
  </ng-container>
  <ng-container
    *ngIf="!address.municipalitySubdivision && address.municipality"
  >
    {{ address.municipality }}
  </ng-container>
  <div></div>
  <div>
    {{ address.postcode }}
    {{ address.countrySecondarySubdivision }}
    {{ address.countrySubdivision }}
  </div>
</div>
