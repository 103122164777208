import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivateChild,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable, first, map } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { environment } from 'src/environments/environment';
import { first as _first } from 'lodash-es';

@Injectable({
  providedIn: 'root',
})
export class OnboardGuard implements CanActivateChild {
  constructor(private _auth: AuthService, private _router: Router) {}

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return this._auth.user$.pipe(
      first(),
      map((user) => {
        if (
          user != null &&
          user.traders.length >= environment.maxTradersPerUser
        ) {
          return this._router.createUrlTree([
            '/mi-chamba',
            _first(user.traders)?.traderID,
          ]);
        }
        return true;
      })
    );
  }
}
