<p-messages
  *ngIf="_errorMessage"
  styleClass="m-4"
  severity="error"
  [value]="[{ severity: 'error', summary: 'Error', detail: _errorMessage }]"
>
</p-messages>
<div class="sign-up-card max-w-22rem mx-auto">
  <header class="text-center flex flex-column gap-1 pb-4">
    <img
      class="mx-auto max-h-4rem"
      src="assets/ChecaChamba-icono-full-color.svg"
      alt=""
    />
    <h3>Nueva Cuenta</h3>
    <p>Registrarte es rápido</p>
  </header>
  <asl-google-signin-button
    size="large"
    text="continue_with"
    type="standard"
    width="350"
  ></asl-google-signin-button>
  <app-facebook-login-button
    class="w-full"
    (click)="_signUpWithFacebook()"
  ></app-facebook-login-button>
  <p-message
    *ngIf="_socialErrorMessage"
    severity="error"
    styleClass="w-full"
    [text]="_socialErrorMessage"
  ></p-message>
  <p-divider align="center">O</p-divider>
  <form
    class="flex flex-column gap-2"
    [formGroup]="_emailSignUpForm"
    (ngSubmit)="_signUpWithEmail()"
  >
    <span class="p-float-label">
      <input
        id="float-input"
        type="text"
        pInputText
        formControlName="email"
        class="w-full"
        autocomplete="username"
      />
      <label for="float-input">Email</label>
    </span>
    <small
      *ngIf="
                _emailSignUpForm.controls.email.invalid &&
                _emailSignUpForm.controls.email.errors?.['required'] &&
                (_emailSignUpForm.controls.email.touched || _submitted)
              "
      class="p-error"
      >Email es requerido</small
    >
    <small
      *ngIf="
                _emailSignUpForm.controls.email.invalid &&
                _emailSignUpForm.controls.email.errors?.['email'] &&
                (_emailSignUpForm.controls.email.touched || _submitted)
              "
      class="p-error"
      >Por favor ingresa un correo electrónico</small
    >
    <button
      *ngIf="!_emailSent"
      [disabled]="!_emailSignUpForm.valid || _sendingEmail"
      [label]="'Continuar'"
      [loading]="_sendingEmail"
      label="Continue"
      pButton
      type="submit"
    ></button>
    <p *ngIf="_emailSent" class="text-center">
     Enviado. Por favor revisa tu correo y sigue las instrucciones que te enviamos.
    </p>
  </form>
  <small class="text-center"
    >¿Ya eres miembro? <a [routerLink]="['/auth/login']">Inicia sesión</a></small
  >
</div>
