import { HttpErrorResponse } from '@angular/common/http';

export const mapErrorToString = (
  error: Error | HttpErrorResponse | unknown,
  message?: string
) => {
  if (message != null) {
    return message;
  }
  if (error instanceof HttpErrorResponse) {
    return error.message;
  }
  return String(error);
};
