<ng-container *ngIf="state$ | async as state">
  <img
    *ngIf="state.type === 'loaded' && state.avatar != null"
    [src]="state.avatar"
  />
  <img
    *ngIf="state.type === 'loaded' && state.avatar == null"
    src="assets/ChecaChamba-logo-20-cropped.jpg"
  />
</ng-container>
