import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { BehaviorSubject, map } from 'rxjs';
import {
  containsLowercaseRegex,
  containsUppercaseRegex,
  containsNumericRegex,
  atLeast8CharactersRegex,
} from '../forms/validators';

@Component({
  selector: 'app-password-strength-feedback',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './password-strength-feedback.component.html',
  styleUrls: ['./password-strength-feedback.component.scss'],
})
export class PasswordStrengthFeedbackComponent implements OnInit {
  private _value: string | undefined | null;
  private _valueSubject = new BehaviorSubject<string | null | undefined>(
    undefined
  );
  private _value$ = this._valueSubject.asObservable();

  _containsLowercase$ = this._value$.pipe(
    map((password) =>
      password == null || password.length === 0
        ? false
        : containsLowercaseRegex.test(password)
    )
  );
  _containsUppercase$ = this._value$.pipe(
    map((password) =>
      password == null || password.length === 0
        ? false
        : containsUppercaseRegex.test(password)
    )
  );
  _containsNumeric$ = this._value$.pipe(
    map((password) =>
      password == null || password.length === 0
        ? false
        : containsNumericRegex.test(password)
    )
  );
  _atLeast8Characters$ = this._value$.pipe(
    map((password) =>
      password == null || password.length === 0
        ? false
        : atLeast8CharactersRegex.test(password)
    )
  );

  @Input()
  public get value(): string | undefined | null {
    return this._value;
  }

  public set value(v: string | undefined | null) {
    this._value = v;
    this._valueSubject.next(v);
  }

  constructor() {}

  ngOnInit(): void {}
}
