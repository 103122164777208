import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuComponent } from './menu/menu.component';
import { MenuModule } from 'primeng/menu';
import { ButtonModule } from 'primeng/button';
import { HttpClientModule } from '@angular/common/http';
import { AppStabilizedService } from './app-stabilized.service';
import { AuthRenewalService } from '../auth/auth-renewal.service';
import { MessageService } from 'primeng/api';
import { HandleErrorService } from './handle-error.service';

@NgModule({
  declarations: [MenuComponent],
  imports: [CommonModule, MenuModule, ButtonModule, HttpClientModule],
  providers: [MessageService, HandleErrorService],
  exports: [MenuComponent],
})
export class CoreModule {
  constructor(
    appStabilized: AppStabilizedService,
    authRenewal: AuthRenewalService
  ) {}
}
