import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { debounceTime, map } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {
  userMenuItems$ = this._auth.user$.pipe(
    debounceTime(300),
    map((user) => {
      const result: MenuItem[] = [];
      if (!user) {
        result.push(
          {
            label: 'Iniciar sesión',
            routerLink: '/auth/login',
          },
          {
            label: 'Registrar',
            routerLink: '/auth/registrar',
          }
        );
      } else {
        // user profile item
        result.push({
          label: user.name,
          icon: 'pi pi-id-card',
          routerLink: '/mi-perfil',
        });
        // Items for each trader
        result.push({ separator: true });
        user.traders.forEach((item, i) => {
          result.push({
            label: item.name,
            icon: 'pi pi-user-edit',
            routerLink: `/mi-chamba/${item.traderID}`,
          });
        });
        if (user.traders.length < environment.maxTradersPerUser) {
          result.push({
            label: 'Añadir chambeador',
            icon: 'pi pi-user-plus',
            routerLink: '/onboard',
          });
        }
        result.push({ separator: true });
        result.push({
          label: 'Salir',
          icon: 'pi pi-sign-out',
          command: () => {
            this._auth.signOut();
            this._router.navigate(['/']);
          },
        });
      }
      return result;
    })
  );

  constructor(private _auth: AuthService, private _router: Router) {}

  ngOnInit(): void {}
}
